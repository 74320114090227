import React, { useEffect, useState } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import CustomInput from "../../../components/inputs/CustomInput";
import { imgUrl } from "../../../helpers/recursosurl";
import ToogleButton from "../../../components/buttons/ToogleButton";
import { estados, getAuthUser, paises, municipios, getCps, usuariosResource, bancariosResource, getUsosRegimen, enfermeroResource, clientesResource } from "../../../helpers/configRoutes/backend_helper";
import { Link, useNavigate } from "react-router-dom";
import { FaStripeS, FaExclamationTriangle } from "react-icons/fa"; // Importar íconos de react-icons
import * as Yup from "yup";
import { useFormik } from "formik";
import { validaRfc, validaVencimiento } from "../../../helpers/formularios/Validation";
import { showFormErrors } from "../../../helpers/showErrors";
import CustomSelect from "../../../components/inputs/CustomSelect";
import ConfirmModal from "../../../components/modals/ConfirmDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../store/auth/login/action";

const InformacionCobro = () => {
    const [c_municipios, setCMunicipios] = useState([]);
    const [c_paises, setCpaises] = useState([])
    const [c_estados, setCEstados] = useState([])
    const [cps, setCps] = useState([])
    const [showOptions, setShowOptions] = useState(false)
    const [datosBancarios, setDatosBancarios] = useState([])
    const usuario = useSelector(state => state.Login.user)
    const [authUser,setAuthUser]=useState(usuario)
    const dispatch = useDispatch();

    const getDatosB = async () => {
        const responseBancarios = await bancariosResource('get', {});
        setDatosBancarios(responseBancarios.bancariosDatos)
    }

    useEffect(() => {
        const getPaises = async () => {
            const response = await paises();
            const responseEstados = await estados();
            setCpaises(response.paises)
            setCEstados(responseEstados.estados)
        }
        getPaises()
        getDatosB()
    }, []);

    useEffect(() => {
        const userData = async() => {
            console.log(usuario.id)
            const response = await clientesResource('show',{},usuario.id);
            console.log(response)
            setAuthUser(response.cliente)
        }
        userData()
    }, [usuario.id]);

    const [loading, setLoading] = useState(false);
    
    
    const navigate = useNavigate();
    const [editar, setEditar] = useState(false);

    const maskedCardNumber = (cardNumber) => {
        if (cardNumber && cardNumber.length > 4) {
            // Reemplaza todos los caracteres excepto los últimos 4 con asteriscos
            return cardNumber.slice(0, -4).replace(/\d/g, '*') + cardNumber.slice(-4);
        }
        return cardNumber;
    };

    const is_factura = (input) => {
        if (validation.values.is_factura) {
            return input == "str" ?
                Yup.string().required('Campo requerido')
                :
                (
                    input == "email" ?
                        Yup.string()
                            .email('Correo electrónico inválido')
                            .required('Campo requerido')
                        :
                        (input == 'rfc' ?
                            validaRfc()
                            :
                            Yup.number()
                                .typeError('Debe ser un número')
                                .required('Campo requerido')
                                .test('len', 'Debe tener exactamente 5 dígitos', val => val && val.toString().length === 5)
                        )
                )
        } else {
            return Yup.string();
        }
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: authUser.bancarios,
        validationSchema: Yup.object({
            calle: Yup.lazy(value => {
                return is_factura('str')
            }),
            cp: Yup.lazy(value => {
                return is_factura('str')
            }),
            numeroExterior: Yup.lazy(value => {
                return is_factura('str')
            }),
            numeroInterior: Yup.string(),
            pais: Yup.lazy(value => {
                return is_factura('str')
            }),
            estado: Yup.lazy(value => {
                return is_factura('str')
            }),
            municipio: Yup.lazy(value => {
                return is_factura('str')
            }),
            colonia: Yup.lazy(value => {
                return is_factura('str')
            }),
            razon_social: Yup.lazy(value => {
                return is_factura('str')
            }),
            email: Yup.lazy(value => {
                return is_factura('email')
            }),

            rfc: Yup.lazy(value => {
                return is_factura('rfc')
            }),
        }),
        onSubmit: async (values, { setErrors }) => {
            authUser.bancarios = values
            const response = await usuariosResource('put', authUser, authUser.id);

            if (response.status) {
                let storedData = localStorage.getItem('authUser');
                if (storedData) {
                    
                    let userData = JSON.parse(storedData);
                    userData.user = response.item;
                    dispatch(setUser(response.item))
                    //localStorage.setItem('authUser', JSON.stringify(userData));
                    setEditar(!editar)
                }
            }
        },
    });

    useEffect(() => {
        const getMunicipios = async () => {
            const response = await municipios({ params: { estado_id: validation.values.estado } });
            setCMunicipios(response.municipios)
        }
        getMunicipios()
    }, [validation.values.estado]);

    const [usosCfdi, setUsosCfdi] = useState([])
    const [regimen, setRegimen] = useState([])

    useEffect(() => {
        const getUsos = async () => {
            const response = await getUsosRegimen();
            setUsosCfdi(response.usos)
            setRegimen(response.regimens)
        }
        getUsos()
    }, []);

    const loadOptions = async (input) => {
        validation.setFieldValue('cp_id', null)
        if (input.length > 3) {
            setShowOptions(true)
            const options = await getCps({ params: { search: input } });
            setCps(options.cps);
        } else {
            setShowOptions(false)
        }
    };

    const setOptionCp = (option) => {
        validation.setFieldValue('cp', `${option.cp} - ${option.asentamiento}`)
        validation.setFieldValue('cp_id', option.id)
        setShowOptions(false)
        validation.setFieldValue('estado', option.estado_id)
        validation.setFieldValue('pais', 1)
        validation.setFieldValue('municipio', option.municipio_id)
        validation.setFieldValue('colonia', option.asentamiento)
    }
    const [modal, setModal] = useState({ show: false })
    const [modalDelete, setModalDelete] = useState({ show: false, textButton: 'Eliminar' })
    const deleteItem = async (id) => {
        const response = await bancariosResource('del', {}, id)
        if (response.status) {
            setModalDelete({ ...modalDelete, show: false, loading: false })
            getDatosB()
        } else {
            setModalDelete({ ...modalDelete, loading: false })
        }
    }
    return (
        <>
            <div className="flex flex-col max-w-full lg:max-w-[75%] ml-auto mr-auto px-4 lg:ml-[19%] lg:mr-[60px]">
                <div className="flex flex-wrap max-w-full md:max-w-[75%] ml-4 p-4">
                    <div className="w-full mb-4">
                        <b className="block mb-2 text-xl">Esta es tu central de información.</b>
                        <p>Aquí puedes consultar y editar tus datos para conectar con especialistas en enfermería.</p>
                    </div>
                </div>
                <div className="flex items-center justify-between ml-4 font-bold text-[24px]">
                    <span>Métodos de pago</span>
                    <CustomButtonOval className="mr-3 mb-2 bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover" onClick={() => navigate(`/metodo-pago`)}>
                        Agregar nuevo método de pago
                    </CustomButtonOval>
                </div>

                {datosBancarios.map((b, index) => (
                    <div className={`border rounded-lg p-4 mb-4 ${b.principal ? 'bg-o2o-gris-calido-hover' : ''} w-full mt-[24px] `} key={index}>
                        <div className="flex items-center justify-between">
                            <div className="ml-4 font-bold text-base">
                                {b.principal ? 'Principal' : 'Secundario'} | Tarjeta Visa
                            </div>
                            <div className="flex-grow text-center font-bold text-base">
                                {maskedCardNumber(b.tarjeta)}
                            </div>
                            <div className="flex space-x-2">
                                <CustomButtonOval onClick={() => navigate(`/metodo-pago/${b.id}`)} className="bg--gris-arena-button text-sm hover:bg-gris-arena-hover">editar</CustomButtonOval>
                                <CustomButtonOval onClick={() => setModalDelete({ ...modalDelete, show: true, title: 'Eliminar Tarjeta', text: '¿Estas seguro de eliminar la tarjeta?', item: b })} className="bg--gris-arena-button text-sm hover:bg-gris-arena-hover">Eliminar</CustomButtonOval>
                            </div>
                        </div>
                        <hr className="border-gray-300 my-4" />
                        <div className="flex space-x-4">
                            <div className="flex flex-col w-1/4">
                                <div className="text-sm font-bold">
                                    Nombre del tarjetahabiente
                                </div>
                                <div className="text-sm">
                                    {b.tarjeta_nombre}
                                </div>
                            </div>
                            <div className="flex flex-col w-1/4">
                                <div className="text-sm font-bold">
                                    Número de tarjeta
                                </div>
                                <div className="text-sm">
                                    {maskedCardNumber(b.tarjeta)}
                                </div>
                            </div>
                            <div className="flex flex-col w-1/6">
                                <div className="text-sm font-semibold">
                                    Fecha de vencimiento
                                </div>
                                <div className="text-sm">
                                    {b.vencimiento_mes}/{b.vencimiento_ano}
                                </div>
                            </div>
                            <div className="flex flex-col w-1/6">
                                {b.registroStripe ? (
                                    <>
                                        <FaStripeS className="text-blue-500 text-2xl" title="Pago registrado en Stripe" />
                                    </>
                                ) : (
                                    <>
                                        <FaExclamationTriangle
                                            className="text-red-500 text-2xl"
                                            title="Método de pago no registrado en Stripe"
                                        />
                                        <p className="text-sm text-gray-500 mt-2 text-center">
                                            Método de pago no registrado
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}

                <div className="flex items-start">
                    <img src={`${imgUrl}/Frame 51.png`} alt="Alert Icon" className="h-[24px] w-[24px] object-contain" />

                    <p className="text-sm">
                        <strong>No haremos ningún cobro</strong> hasta que solicites una contratación y ésta sea aprobada.
                    </p>
                </div>
                <div className="flex items-center justify-between ml-4 font-bold text-[24px] mt-[49px] mb-[24px]">
                    Datos de facturación
                </div>

                <div className="text-base font-semibold mb-[24px]">
                    <div className="bg-o2o-gris-calido-hover shadow-lg rounded-lg py-3 px-4">
                        <div className="flex items-center justify-between">
                            <span className="mr-4">¿Requiero factura?</span>
                            <ToogleButton
                                name="requiereFactura"
                                label="¿Requiero factura?"
                                checked={validation.values.is_factura}
                                onChange={() => {
                                    validation.setFieldValue('is_factura', !validation.values.is_factura);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="border rounded-lg p-4 mb-4 w-full md:w-[calc(100% - 30px)">
                    <div className="flex items-center justify-between">
                        <div className="ml-4 font-bold text-base">
                            Datos personales de facturación
                        </div>

                        <div className="flex space-x-2">
                            <CustomButtonOval className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover" onClick={() => [setEditar(!editar)]}>{editar ? 'Cancelar' : 'Editar'}</CustomButtonOval>

                        </div>
                    </div>
                    <hr className="border-gray-300 my-4" />
                    {editar ?
                        <>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    showFormErrors(validation);
                                    return false;
                                }}

                            >

                                <div className="grid grid-cols-4 gap-4">
                                    <div className="col-span-1">
                                        <div className="text-sm font-semibold">Nombre(s)</div>
                                        <div className="text-sm">
                                            <CustomInput
                                                name="razon_social"
                                                value={validation.values.razon_social || ''}
                                                error={validation.errors?.razon_social}
                                                placeholder="Nombre Completo"
                                                type="text"
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-sm font-semibold">RFC</div>
                                        <div className="text-sm">
                                            <CustomInput
                                                name="rfc"
                                                value={validation.values.rfc || ''}
                                                error={validation.errors?.rfc}
                                                placeholder="RFC"
                                                type="text"
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-sm font-semibold">Email</div>
                                        <div className="text-sm">
                                            <CustomInput
                                                name="email"
                                                value={validation.values.email || authUser.email}
                                                error={validation.errors?.email}
                                                placeholder="Email"
                                                type="text"
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="text-sm font-semibold">Regimen Fiscal</div>
                                        <div className="text-sm">
                                            <CustomSelect
                                                name="regimen_id"
                                                options={regimen}
                                                value={validation.values.regimen_id || ''}
                                                error={validation.errors.regimen_id}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="col-span-1">
                                            <div className="text-sm font-semibold">Uso del CFDI</div>
                                            <div className="text-sm">
                                                <CustomSelect
                                                    name="uso_id"
                                                    options={usosCfdi}
                                                    value={validation.values.uso_id || ''}
                                                    error={validation.errors.uso_id}
                                                    onChange={validation.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="text-sm font-semibold">Telefono</div>
                                        <div className="text-sm">
                                            <CustomInput
                                                name="telefono"
                                                value={validation.values.telefono || ''}
                                                error={validation.errors?.telefono}
                                                placeholder="Telefono"
                                                type="text"
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-sm font-semibold">Código Postal</div>
                                        <div className="text-sm relative">
                                            <CustomInput
                                                name="cp"
                                                value={validation.values.cp || ''}
                                                error={validation.errors?.cp}
                                                placeholder="Código postal"
                                                type="text"
                                                onChange={(e) => [validation.setFieldValue('cp', e.target.value), loadOptions(e.target.value)]}
                                                maxLength="5"
                                            />
                                            {showOptions && (
                                                <div className="absolute mt-1 w-full border border-gray-300 rounded-lg shadow-lg bg-white z-10 max-h-40 overflow-y-auto">
                                                    {cps.length > 0 ? (
                                                        cps.map(option => (
                                                            <div
                                                                key={option.id}
                                                                onClick={() => setOptionCp(option)}
                                                                className="p-2 cursor-pointer hover:bg-gray-100"
                                                            >
                                                                {option.cp} - {option.asentamiento}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="p-2 text-gray-500">No options found</div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-sm font-semibold">Calle</div>
                                        <div className="text-sm">
                                            <CustomInput
                                                name="calle"
                                                value={validation.values.calle || ''}
                                                error={validation.errors?.calle}
                                                placeholder="Calle"
                                                type="text"
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-sm font-semibold">Número exterior</div>
                                        <div className="text-sm">
                                            <CustomInput
                                                name="numeroExterior"
                                                value={validation.values.numeroExterior || ''}
                                                error={validation.errors?.numeroExterior}
                                                placeholder="Número exterior"
                                                type="text"
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-sm font-semibold">Número interior</div>
                                        <div className="text-sm">
                                            <CustomInput
                                                name="numeroInterior"
                                                value={validation.values.numeroInterior || ''}
                                                error={validation.errors?.numeroInterior}
                                                placeholder="Número interior"
                                                type="text"
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-1">
                                        <div className="text-sm font-semibold">Colonia</div>
                                        <div className="text-sm">
                                            <CustomInput
                                                name="colonia"
                                                value={validation.values.colonia || ''}
                                                error={validation.errors?.colonia}
                                                placeholder="Colonia"
                                                type="text"
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-4 gap-4 mt-4">
                                    <div className="col-span-2">
                                        <div className="text-sm font-semibold">Municipio</div>
                                        <div className="text-sm">
                                            <CustomSelect
                                                name="municipio"
                                                options={[
                                                    { label: 'Seleccione una opción.', value: 0 },
                                                    ...c_municipios.map(tipo => ({
                                                        value: tipo?.id,
                                                        label: tipo?.nombre,
                                                    })),
                                                ]}
                                                disabled={true}
                                                value={validation.values.municipio || ''}
                                                error={validation.errors?.municipio}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className="text-sm font-semibold">Estado</div>
                                        <div className="text-sm">
                                            <CustomSelect
                                                name="estado"
                                                options={[
                                                    { label: 'Seleccione una opción.', value: 0 },
                                                    ...c_estados.map(tipo => ({
                                                        value: tipo?.id,
                                                        label: tipo?.nombre,
                                                    })),
                                                ]}
                                                disabled={true}
                                                value={validation.values.estado || ''}
                                                error={validation.errors?.estado}
                                                onChange={validation.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex justify-center mt-6">
                                    <CustomButtonOval type={'submit'} className="bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover">
                                        Guardar
                                    </CustomButtonOval>
                                </div>

                            </form>
                        </> : <>
                            <div className="grid grid-cols-4 gap-4">
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Nombre(s)
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios.razon_social}
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        RFC
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios.rfc}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Email
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios.email || authUser.email}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Uso de CFDI
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios?.uso?.id} {authUser.bancarios?.uso?.descripcion}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Regimen Fiscal
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios?.uso?.name} {authUser.bancarios?.regimen?.descripcion}
                                    </div>
                                </div>
                            </div>


                            <div className="grid grid-cols-4 gap-4 mt-4">
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Calle
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios.calle}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Número exterior
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios.numeroExterior}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Número interior
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios.numeroInterior}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Colonia
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios.colonia}
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 mt-4">
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Municipio
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios.municipio_nombre}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Estado
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios.estado_nombre}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        País
                                    </div>
                                    <div className="text-sm">
                                        {'Mexico'}
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-sm font-semibold">
                                        Código Postal
                                    </div>
                                    <div className="text-sm">
                                        {authUser.bancarios.cp}
                                    </div>
                                </div>
                            </div>

                        </>}

                </div>
                {modalDelete.show &&
                    <ConfirmModal modal={modalDelete} setModal={setModalDelete} action={deleteItem} />
                }


            </div>
        </>
    )
}

export default InformacionCobro;
import React from 'react'
import Buscardor2 from '../home/Buscardor2'
import BlogSlides from '../blog/BlogSlides';
import CardsSatisfaccion from './CardsSatisfaccion';
import GoogleSatisfaccion from './GoogleSatisfaccion';
import { imgUrl } from '../../../helpers/recursosurl';
import { useIsMobile } from '../../../store/hooks/useIsMobile';
import CardEnfermeroInfo from './CardEnfermeroInfo';
import RegistrateCliente from '../home/RegistrateCliente';
import { useSelector } from 'react-redux';

const testimonials = [
  {
    id: 1,
    text: "Cuidados enfermeros a domicilio, es un servicio innovador y sumamente seguro. One 2 One Nurses es la mejor opción para contratar enfermeros con alta experiencia profesional y alto sentido humano. ¡Les agradezco el cuidado de mi papá! La enfermera fue lindísima, Recomiendo ampliamente. Considero que es la mejor forma de contratar enfermeros a",
  },
];

function Testimonial({ text }) {
  const { isMobile } = useIsMobile();
  return (
    <blockquote className="font font-bold self-stretch mt-16 w-full text-5xl italic text-white leading-[58px] max-md:mt-10 max-md:max-w-full max-md:text-4xl max-md:leading-[54px] bg-o2o-gris-arena rounded-lg">
      {!isMobile ? text : text.slice(0, 70)}
    </blockquote>
  );
}


const Testimoniales = () => {
  
  return (
    <>
      <Buscardor2 />
      <section class="relative">
        <section className="flex flex-col items-center px-5 font-bold text-black font">
          <h2 className="text-3xl leading-9 text-center max-md:max-w-full">
            Testimoniales del servicio según nuestros clientes
          </h2>
          <p className="mt-5 text-base leading-6 text-center w-[780px] max-md:max-w-full font-normal">
            Nuestra misión es ofrecer{" "}
            <span className="font-bold">oportunidades laborales seguras y justas</span>{" "}
            que permitan autonomía e independencia profesional. Al trabajar en One to One Nurses, puedes elegir libremente tus horarios y clientes.
          </p>

        </section>
        <div className="flex flex-col justify-center items-center ">
          <div className='w-[95%]'>
            {testimonials.map((testimonial) => (
              <Testimonial key={testimonial.id} text={testimonial.text} />
            ))}
          </div>
        </div>
        <BlogSlides />
        <div className='mb-44'></div>
      </section>
      <section className="flex flex-col px-5 text-3xl font-bold leading-9 text-center text-black w-[100%] lg:w-[90%] mb-10">
        <img
          loading="lazy"
          srcSet={`${imgUrl}/satisfaccion-clientes.png`}
          className="w-full aspect-[1.61] max-md:max-w-full"
        />
        <div className="max-md:max-w-full font">
          La satisfacción de nuestros clientes es cuantificable
        </div>
      </section>
      <CardsSatisfaccion />
      
      <div className='mt-20'></div>
      <CardEnfermeroInfo />
      <div className='mt-10'></div>
      <RegistrateCliente />

    </>
  )
}

export default Testimoniales
import { imgUrl } from "./recursosurl";


/* SEGURIDAD */
export const infoBlocks = [
    {
        imgSrc: 'sistema-evaluacion.png',
        imgAlt: "Sistema de evaluación",
        title: "Sistema de evaluación",
        text: "Al concluir el servicio —y como paso forzoso para recibir pagos y facturas— ambas partes deberán calificarse; manteniendo así la calidad del servicio."
    },

    {
        imgSrc: "cobro-servicios.png",
        imgAlt: "Cobro de servicios",
        title: "Cobro de servicios",
        text: "El cobro de servicios al cliente es realizado previo al inicio del mismo. Esto garantiza tu pago de honorarios."
    },
    {
        imgSrc: "personal-identificado.png",
        imgAlt: "Personal identificado",
        title: "Personal identificado",
        text: "El enfermero se presentará con identificación, uniforme y materiales básicos inherentes a su trabajo."
    },

    {
        imgSrc: "material-especializado.png",
        imgAlt: "Material especializado",
        title: "Material especializado",
        text: "En caso de ser necesario, el cliente proporcionará material adicional para el cuidado del paciente."
    },
    {
        imgSrc: "privacidad-datos.png",
        imgAlt: "Privacidad de datos",
        title: "Privacidad de datos",
        text: "La información recabada será usada únicamente con el propósito del servicio y será eliminada según los términos y condiciones de nuestro Aviso de Privacidad."
    },
    

];
/* SEGURIDAD */
export const infoBlocks2 = [
    

    {
        imgSrc: "reporte-anomalias.png",
        imgAlt: "Reporte de anomalías",
        title: "Reporte de anomalías",
        text: "Ofrecemos el Centro de Atención para reportar cualquier situación fuera de las políticas de servicio."
    },
    {
        imgSrc: "denuncia-clientes.png",
        imgAlt: "Denuncia de clientes",
        title: "Denuncia de clientes",
        text: "Nuestro Centro de Atención para profesionistas te permite reportar toda situación incómoda y/o peligrosa."
    },

    {
        imgSrc: "clasificacion.png",
        imgAlt: "Calificación y satisfacción",
        title: "Calificación y satisfacción",
        text: "Tus comentarios y calificación nos permiten ajustar el servicio al momento para satisfacer tus necesidades."
    },
    
    {
        imgSrc: "clasificacion-red.png",
        imgAlt: "Calificación para una red segura",
        title: "Calificación para una red segura",
        text: "Calificar a los clientes nos permiten actuar y crear una red segura para ti y otros profesionistas."
    },
];

/* SEGURIDAD */
export const cardsStep1 = [
    {
        img: `${imgUrl}/antes-servicio-1.png`,
        titulo: 'Uso estricto de perfiles',
        descripcion: `Los servicios de One to One sólo pueden ser usados con una
        cuenta activa, al estar loggeado en el sitio. Sin excepciones
        para clientes y proveedores.`
    },
    {
        img: `${imgUrl}/antes-servicio-2.png`,
        titulo: 'Privacidad de la información',
        descripcion: `Datos de contacto o domicilio jamás serán revelados —incluso
        estando loggeado al sitio— hasta que el trato sea cerrado y se
        usen los protocolos pertinentes.`
    },
    {
        img: `${imgUrl}/antes-servicio-3.png`,
        titulo: 'Validación de perfiles',
        descripcion: `One to One valida y respalda a sus proveedores, a través de la Escuela
        de Enfermería Angelópolis; institución que garantiza excelencia
        profesional.`
    },
];


export const cardsStep2 = [
    {
        img: `${imgUrl}/durante-servicio1.png`,
        titulo: 'Código de conducta',
        descripcion: `Ambos perfiles habrán firmado previamente el cumplir con los códigos de
        conducta que garantizan el buen trato y los espacios seguros.`
    },
    {
        img: `${imgUrl}/durante-servicio2.png`,
        titulo: 'Meet virtual',
        descripcion: `La realización de un servicio depende completamente de ambas partes
        aceptando la oferta. El cliente elegirá al profesionista y éste puede
        aceptar o declinar.`
    },
    {
        img: `${imgUrl}/durante-servicio3.png`,
        titulo: '«Handshake» virtual con QR',
        descripcion: `Para cerrar el trato —y previo a la cita personal— el cliente y
        proveedor deberán escanear el código QR asignado a la contraparte.`
    }
];


export const cardsStep3 = [
    {
        img: `${imgUrl}/despues-servicio1.png`,
        titulo: 'Sistema de evaluación',
        descripcion: `Al concluir el servicio y como paso forzoso para recibir pagos y facturas, 
        ambas partes deberán calificarse; así como el servicio de la plataforma.`
    },
    {
        img: `${imgUrl}/despues-servicio2.png`,
        titulo: 'Historial de servicios',
        descripcion: `One to One guarda el historial de servicios y éste podrá ser consultado por ambas partes en su Central de Información.`
    },
];

/* COSOTS U MEMBRESIAS */
export const serviceCards = [
    {
        src: imgUrl + '/tirnos.png',
        alt: "Precio = turnos solicitados",
        title: "Precio = turnos solicitados",
        description: "Ofrecemos tres turnos —plazos de horas— a contratar: 8, 10 y 12 horas al día. De esta forma simplificamos y transparentamos lo que pagas."
    },
    {
        src: imgUrl + '/nivel.png',
        alt: "Nivel profesional",
        title: "Nivel profesional",
        description: "One to One Nurses asignará internamente un nivel al profesionista de acuerdo a su formación y experiencia profesional. Esto permitirá el pago de cuotas justas de acuerdo a las necesidades reales del paciente."
    },
    {
        src: imgUrl + '/pafo.png',
        alt: "Pago digno a proveedores",
        title: "Pago digno a proveedores",
        description: "One to One Nurses sabe que la enfermería es igual de importante que otras disciplinas médicas. Los pagos a profesionistas corresponden al 70% del monto total cobrado."
    }
];

export const levels = [
    { level: "Nivel 1", description: <div><span className='font-bold'>Técnicos en enfermería</span><br></br> [conocimientos básicos prácticos]</div>, experienceYears: "1 a 3 años de experiencia profesional" },
    { level: "Nivel 2", description: "Licenciados en enfermería [conocimientos avanzados]", experienceYears: "3 a 8 años de experiencia profesional" },
    { level: "Nivel 3", description: "Posgrado en enfermería [conocimientos especializados]", experienceYears: "+10 años de experiencia profesional" }
];

export const pricingHours = [
    {
        hours: "8 horas al día", levels: [
            { level: "Nivel 1", prices: [{ amount: "800.00", time: "MATUTINO" }, { amount: "850.00", time: "VESPERTINO" }, { amount: "900.00", time: "NOCTURNO" }] },
            { level: "Nivel 2", prices: [{ amount: "900.00", time: "MATUTINO" }, { amount: "950.00", time: "VESPERTINO" }, { amount: "1,000.00", time: "NOCTURNO" }] },
            { level: "Nivel 3", prices: [{ amount: "1,000.00", time: "MATUTINO" }, { amount: "1,050.00", time: "VESPERTINO" }, { amount: "1,100.00", time: "NOCTURNO" }] }
        ]
    },
    {
        hours: "10 horas al día", levels: [
            { level: "Nivel 1", prices: [{ amount: "900.00", time: "MATUTINO" }, { amount: "950.00", time: "VESPERTINO" }, { amount: "1,000.00", time: "NOCTURNO" }] },
            { level: "Nivel 2", prices: [{ amount: "1,000.00", time: "MATUTINO" }, { amount: "1,050.00", time: "VESPERTINO" }, { amount: "1,100.00", time: "NOCTURNO" }] },
            { level: "Nivel 3", prices: [{ amount: "1,100.00", time: "MATUTINO" }, { amount: "1,150.00", time: "VESPERTINO" }, { amount: "1,200.00", time: "NOCTURNO" }] }
        ]
    },
    {
        hours: "12 horas al día", levels: [
            { level: "Nivel 1", prices: [{ amount: "1,000.00", time: "MATUTINO" }, { amount: "1,050.00", time: "VESPERTINO" }, { amount: "1,100.00", time: "NOCTURNO" }] },
            { level: "Nivel 2", prices: [{ amount: "1,100.00", time: "MATUTINO" }, { amount: "1,150.00", time: "VESPERTINO" }, { amount: "1,200.00", time: "NOCTURNO" }] },
            { level: "Nivel 3", prices: [{ amount: "1,200.00", time: "MATUTINO" }, { amount: "1,250.00", time: "VESPERTINO" }, { amount: "1,300.00", time: "NOCTURNO" }] }
        ]
    }
];

export const getEstrellas = (no) => {
    switch (parseInt(no)) {
        case 1:
            return <>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined">star_rate</span>
                <span class="material-symbols-outlined">star_rate</span>
                <span class="material-symbols-outlined">star_rate</span>
                <span class="material-symbols-outlined">star_rate</span>
            </>
        case 2:
            return <>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined">star_rate</span>
                <span class="material-symbols-outlined">star_rate</span>
                <span class="material-symbols-outlined">star_rate</span>
            </>
        case 3:
            return <>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined">star_rate</span>
                <span class="material-symbols-outlined">star_rate</span>
            </>
        case 4:
            return <>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined">star_rate</span>
            </>
        case 5:
            return <>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
                <span class="material-symbols-outlined text-[#FFA000]">star_rate</span>
            </>
        default:
            break;
    }
}

export const blogsInfo = [
    {
        img: `${imgUrl}/blog-1.png`,
        titulo: `Los cuidados post-natal
        que un profesional puede enseñarte`,
        autor: 'Por Lic. en enfermería María Martínez',
        fecha: 'Marzo 17 2023',
        descripcion: `Durante las primeras horas y días posteriores al nacimiento, las madres experimentan una serie de cambios físicos y emocionales, mientras que los recién nacidos se adaptan a su nuevo entorno. Los enfermeros especializados en atención post-natal desempeñan un papel crucial en la monitorización de la salud de la madre y el bebé, brindando educación sobre la lactancia materna, el autocuidado y ayudando a las familias a navegar por esta fase de transición.`
    },
    {
        img: `${imgUrl}/blog-2.png`,
        titulo: `Cuidados y recomendaciones post-quirúrgicas para la recuperación en casa`,
        autor: 'Por Lic. en enfermería María Martínez',
        fecha: 'Marzo 17 2023',
        descripcion: `Durante las primeras horas y días posteriores al nacimiento, las madres experimentan una serie de cambios físicos y emocionales, mientras que los recién nacidos se adaptan a su nuevo entorno. Los enfermeros especializados en atención post-natal desempeñan un papel crucial en la monitorización de la salud de la madre y el bebé, brindando educación sobre la lactancia materna, el autocuidado y ayudando a las familias a navegar por esta fase de transición.`
    },
    {
        img: `${imgUrl}/blog-3.png`,
        titulo: `Protocolos para facilitarle al enfermero el cuidado del paciente en el domicilio`,
        autor: 'Por Lic. en enfermería María Martínez',
        fecha: 'Marzo 17 2023',
        descripcion: `Durante las primeras horas y días posteriores al nacimiento, las madres experimentan una serie de cambios físicos y emocionales, mientras que los recién nacidos se adaptan a su nuevo entorno. Los enfermeros especializados en atención post-natal desempeñan un papel crucial en la monitorización de la salud de la madre y el bebé, brindando educación sobre la lactancia materna, el autocuidado y ayudando a las familias a navegar por esta fase de transición.`
    },
    {
        img: `${imgUrl}/blog-7.svg`,
        titulo: `Los cuidados post-natal
        que un profesional puede enseñarte`,
        autor: 'Por Lic. en enfermería María Martínez',
        fecha: 'Marzo 17 2023',
        descripcion: `Durante las primeras horas y días posteriores al nacimiento, las madres experimentan una serie de cambios físicos y emocionales, mientras que los recién nacidos se adaptan a su nuevo entorno. Los enfermeros especializados en atención post-natal desempeñan un papel crucial en la monitorización de la salud de la madre y el bebé, brindando educación sobre la lactancia materna, el autocuidado y ayudando a las familias a navegar por esta fase de transición.`
    },
    {
        img: `${imgUrl}/blog8.svg`,
        titulo: `Cuidados y recomendaciones post-quirúrgicas para la recuperación en casa`,
        autor: 'Por Lic. en enfermería María Martínez',
        fecha: 'Marzo 17 2023',
        descripcion: `Durante las primeras horas y días posteriores al nacimiento, las madres experimentan una serie de cambios físicos y emocionales, mientras que los recién nacidos se adaptan a su nuevo entorno. Los enfermeros especializados en atención post-natal desempeñan un papel crucial en la monitorización de la salud de la madre y el bebé, brindando educación sobre la lactancia materna, el autocuidado y ayudando a las familias a navegar por esta fase de transición.`
    },
    {
        img: `${imgUrl}/blog-3.png`,
        titulo: `Protocolos para facilitarle al enfermero el cuidado del paciente en el domicilio`,
        autor: 'Por Lic. en enfermería María Martínez',
        fecha: 'Marzo 17 2023',
        descripcion: `Durante las primeras horas y días posteriores al nacimiento, las madres experimentan una serie de cambios físicos y emocionales, mientras que los recién nacidos se adaptan a su nuevo entorno. Los enfermeros especializados en atención post-natal desempeñan un papel crucial en la monitorización de la salud de la madre y el bebé, brindando educación sobre la lactancia materna, el autocuidado y ayudando a las familias a navegar por esta fase de transición.`
    },
    {
        img: `${imgUrl}/blog-4.svg`,
        titulo: `Los cuidados post-natal
        que un profesional puede enseñarte`,
        autor: 'Por Lic. en enfermería María Martínez',
        fecha: 'Marzo 17 2023',
        descripcion: `Durante las primeras horas y días posteriores al nacimiento, las madres experimentan una serie de cambios físicos y emocionales, mientras que los recién nacidos se adaptan a su nuevo entorno. Los enfermeros especializados en atención post-natal desempeñan un papel crucial en la monitorización de la salud de la madre y el bebé, brindando educación sobre la lactancia materna, el autocuidado y ayudando a las familias a navegar por esta fase de transición.`
    },
    {
        img: `${imgUrl}/blog-5.svg`,
        titulo: `Cuidados y recomendaciones post-quirúrgicas para la recuperación en casa`,
        autor: 'Por Lic. en enfermería María Martínez',
        fecha: 'Marzo 17 2023',
        descripcion: `Durante las primeras horas y días posteriores al nacimiento, las madres experimentan una serie de cambios físicos y emocionales, mientras que los recién nacidos se adaptan a su nuevo entorno. Los enfermeros especializados en atención post-natal desempeñan un papel crucial en la monitorización de la salud de la madre y el bebé, brindando educación sobre la lactancia materna, el autocuidado y ayudando a las familias a navegar por esta fase de transición.`
    },
    {
        img: `${imgUrl}/blog-6.svg`,
        titulo: `Protocolos para facilitarle al enfermero el cuidado del paciente en el domicilio`,
        autor: 'Por Lic. en enfermería María Martínez',
        fecha: 'Marzo 17 2023',
        descripcion: `Durante las primeras horas y días posteriores al nacimiento, las madres experimentan una serie de cambios físicos y emocionales, mientras que los recién nacidos se adaptan a su nuevo entorno. Los enfermeros especializados en atención post-natal desempeñan un papel crucial en la monitorización de la salud de la madre y el bebé, brindando educación sobre la lactancia materna, el autocuidado y ayudando a las familias a navegar por esta fase de transición.`
    }
];

export const interesesPersonales = [
    { nombre: 'Cocinar', value: 1, tipo: 1 },
    { nombre: 'Leer', value: 2, tipo: 1 },
    { nombre: 'Fotografía', value: 3, tipo: 1 },
    { nombre: 'Bailar', value: 4, tipo: 1 },
    { nombre: 'Meditación', value: 5, tipo: 1 },
    { nombre: 'Teatro', value: 6, tipo: 1 },
    { nombre: 'Cerámica', value: 7, tipo: 1 },
    { nombre: 'Cine', value: 8, tipo: 1 },
    { nombre: 'Viajar', value: 9, tipo: 1 },
    { nombre: 'Apoyo a causas sociales', value: 10, tipo: 1 },
    { nombre: 'Dibujo', value: 11, tipo: 1 },
    { nombre: 'Botánica', value: 12, tipo: 1 },
    { nombre: 'Tejido', value: 13, tipo: 1 },
    { nombre: 'Música', value: 14, tipo: 1 },
    { nombre: 'Mascotas', value: 15, tipo: 1 },
    { nombre: 'Escritura', value: 15, tipo: 1 },
    { nombre: 'Filosofía', value: 17, tipo: 1 },
    { nombre: 'Idiomas', value: 18, tipo: 1 },
    { nombre: 'Jardinería', value: 19, tipo: 1 },
    { nombre: 'Yoga', value: 1, tipo: 2 },
    { nombre: 'Atletismo', value: 2, tipo: 2 },
    { nombre: 'Danza aérea', value: 3, tipo: 2 },
    { nombre: 'Montañismo', value: 4, tipo: 2 },
    { nombre: 'Ciclismo', value: 5, tipo: 2 },
    { nombre: 'Escalada', value: 6, tipo: 2 },
    { nombre: 'Crossfit', value: 7, tipo: 2 },
    { nombre: 'Baloncesto', value: 8, tipo: 2 },
    { nombre: 'Tenis', value: 9, tipo: 2 },
    { nombre: 'Patinaje', value: 10, tipo: 2 },
    { nombre: 'Taekwondo', value: 11, tipo: 2 },
    { nombre: 'Karate', value: 12, tipo: 2 },
    { nombre: 'Box', value: 13, tipo: 2 },
    { nombre: 'Ajedrez', value: 14, tipo: 2 },
    { nombre: 'Pádel', value: 15, tipo: 2 },
    { nombre: 'Squash', value: 16, tipo: 2 },
    { nombre: 'Béisbol', value: 17, tipo: 2 },
    { nombre: 'Fútbol americano', value: 18, tipo: 2 },
    { nombre: 'Natación', value: 19, tipo: 2 },
    { nombre: 'Yoga', value: 20, tipo: 2 },
];
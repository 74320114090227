import React from "react";
import CustomTabsPerfiles from "../../components/CustomTabsPerfiles";
import { getAuthUser } from "../../helpers/configRoutes/backend_helper";
import MisServicios from "../enfermero/mis_servicios/MisServicios";
import ServiciosAdmin from "../administrador/historial_servicios/HistorialServicios";
import UnderConstruction from "../UnderConstruction";

const Servicios = () => {
    const authUser = getAuthUser();
    const tabscliente = [
        { id: 1, label: 'Servicios activos', content: <ServiciosAdmin contenido={1} estatus_id={9} /> },
        { id: 2, label: 'Servicios por confirmar', content: <ServiciosAdmin contenido={1} estatus_id={5} /> },
        { id: 3, label: 'Servicios concluídos', content: <ServiciosAdmin contenido={1} estatus_id={6} /> },
    ];
    const tabs = [
        { id: 1, label: 'Solicitudes de servicio', content: <MisServicios contenido={1} estatus_id={5} /> },
        { id: 2, label: 'Servicios activos', content: <MisServicios contenido={1} estatus_id={9} /> },
        { id: 3, label: 'Servicios concluídos', content: <MisServicios contenido={1} estatus_id={6} /> },
    ];

    return (
        <>
            <div className="flex flex-wrap max-w-full lg:max-w-[75%] mx-auto px-4">
                <div className="p-4">
                    <b className="block mb-2 text-sm font-bold">Esta es tu central de información.</b>
                    <p className="text-sm">Aquí puedes consultar y editar tus datos para conectar con especialistas en enfermería.</p>
                </div>
            </div>
            <CustomTabsPerfiles defaultTab={1} tabs={authUser.perfil_id == 4 ? tabs : tabscliente} />
        </>

    )
}

export default Servicios;
import { useNavigate } from 'react-router-dom'
import CustomButtonOval from '../../../components/buttons/CustomButtonOval'
import { imgUrl } from '../../../helpers/recursosurl'
import React from 'react'
import { useSelector } from 'react-redux'

const Protocolos = () => {
    const config = useSelector((state)=>state.configZendesk.zendeskConfig);
    const navigate = useNavigate();

    return (
        <>
            <div className="flex flex-wrap justify-center mt-10">
    <div className="lg:w-2/3 w-full text-center mb-6">
        <img src={`${imgUrl}/Group_135.png`} alt="Imagen" className="w-full h-auto" />
    </div>
    <div className='flex flex-col w-full max-w-[692px] mx-auto px-4'>
    <div className="text-center mb-[18px]">
        <h1 className="text-2xl lg:text-3xl">Velamos por la seguridad de clientes y profesionistas</h1>
    </div>
    <div className="text-center mb-[18px]">
        <p className="text-base lg:text-lg">
            Nuestros <b>protocolos de seguridad y códigos de conducta</b> garantizan una experiencia profesional, satisfactoria y sensible.
        </p>
    </div>
    {/* Contenedor de los botones */}
    <div className="flex flex-wrap justify-center gap-4">
        <CustomButtonOval
            className="bg-o2o-gris-arena w-full lg:w-auto"
            onClick={() => navigate('/seguridad')}
        >
            <p className='text-sm font-bold'>Conoce más sobre la seguridad</p>
        </CustomButtonOval>
        <CustomButtonOval
            className="bg-o2o-gris-arena w-full lg:w-auto"
            onClick={() => window.open(config.codigo_conducta?.url, "_blank")}
            loading={config.codigo_conducta?.url ? false:true}
            textCarga={'Cargando datos'}
        >
            <p className='text-sm font-bold'>Lee el Código de Conducta</p>
        </CustomButtonOval>
        <CustomButtonOval
            className="bg-o2o-gris-arena w-full lg:w-auto"
            onClick={() => navigate('/testimoniales')}
        >
            <p className='text-sm font-bold'>Ir a Testimoniales</p>
        </CustomButtonOval>
    </div>
</div>

</div>

        </>
    )
}

export default Protocolos
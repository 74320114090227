"use client"
import { imgUrl } from '../../../helpers/recursosurl'
import React from 'react'
import {Link} from 'react-router-dom';

const InfoPerfil = () => {
    return (
        <div className='w-full max-w-o2o'>
            <div className="flex flex-wrap justify-center mt-10 top-full">
                <div className="max-w-[749px] text-center mb-[18px]">
                    <h2>Personas o empresas, esta es una forma fácil y segura de contratar cuidados enfermeros a domicilio</h2>
                </div>
                <div className="lg:w-full text-center mb-3 sm:mb-3 lg:mb-6 md:mb-6 font">
                    <p >¡Conecta con el profesional médico que deseas!</p>
                </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between mt-4 lg:mt-10 md:mt-10 mb-6 ml-8">
                <div className="flex flex-col rounded-xl w-[354.67px] mb-10">
                    <div className='w-full h-[354px] bg-o2o-gris-calido rounded-xl relative mb-[24px]'>
                        <div className='flex justify-center items-center rounded-[18px] bg-o2o-aqua w-[42px] h-[42px] absolute -top-5 -left-5 border-b border-black'><h3>1</h3></div>
                        <img src={`${imgUrl}/perfil/perfil_home_1.png`} alt="Imagen" className="absolute -bottom-7 object-fill w-full " />
                    </div>
                    <h3>Crear un perfil propio o el de un familiar o ser querido</h3>
                </div>
                <div className="flex flex-col rounded-xl w-[354.67px]">
                    <div className='w-full h-[354px] bg-o2o-gris-calido rounded-xl relative mb-[24px]'>
                        <div className='flex justify-center items-center rounded-[18px] bg-o2o-aqua w-[42px] h-[42px] absolute -top-5 -left-5 border-b border-black'><h3>2</h3></div>
                        <img src={`${imgUrl}/perfil/perfil_home_2.png`} alt="Imagen" className="absolute bottom-2 object-fill w-full h-full scale-115" />
                    </div>
                    <h3 className='mb-6'>Uso el buscador inteligente y conecta con el enfermero adecuado para tu paciente</h3>
                </div>
                <div className="flex flex-col rounded-xl w-[354.67px]">
                    <div className='w-full h-[354px] bg-o2o-gris-calido rounded-xl relative mb-[24px]'>
                        <div className='flex justify-center items-center rounded-[18px] bg-o2o-aqua w-[42px] h-[42px] absolute -top-5 -left-5 border-b border-black'><h3>3</h3></div>
                        <img src={`${imgUrl}/perfil/perfil_home_3.png`} alt="Imagen" className="absolute -bottom-2 object-fill w-full " />
                    </div>
                    <h3>Nosotros resolvemos la administración, logística y seguridad</h3>
                </div>
            </div>
            <div className='flex justify-center mt-[60px]'>
            
            <Link  to={'como-funciona'} className='bg-o2o-aqua mx-auto px-4 rounded-[18px] border-b border-black'>
                <p className='text-base font-bold'>¿Cómo funciona One 2 One Nurses?</p>
            </Link>
            </div>
        </div>
    )
}

export default InfoPerfil
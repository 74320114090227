import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useIsMobile } from '../../../store/hooks/useIsMobile';
import { imgUrl } from '../../../helpers/recursosurl';
import { getEstrellas } from '../../../helpers/recursos';
import { useSelector } from 'react-redux';

const BlogSlides = () => {

    const testimoniales = useSelector(
        (state) => state.configPublic.testimoniales_plataforma || []
    );
    console.log(testimoniales,'testimo')
    const { isMobile } = useIsMobile();

    return (
        <>
        {/* CARRUSEL */}
        <div className="self-center w-[100%] max-md:max-w-full absolute -bottom-20">
            <div className='container relative'>
                <div className='swiperContainer'>
                    <Swiper
                        modules={[Navigation, Pagination]}
                        spaceBetween={20}
                        slidesPerView={!isMobile ? 2.7 : 1}
                        navigation
                        pagination={{ clickable: true }}
                    >
                        {testimoniales.map((data, index) => (
                        <SwiperSlide key={index}>
                            <div className="flex flex-col w-[398px] ">
                                <div className="flex flex-col justify-center px-5 py-1.5 max-w-full bg-orange-200 rounded-2xl shadow-sm ">
                                    <div className="flex gap-3">
                                    {new Array(data.calificacion || 0).fill("").map((_, i) => (
                                            <img
                                                src="/icons/start-fill.svg"
                                                alt="star"
                                                key={i}
                                                className="w-4 h-4"
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="flex flex-col p-5 mt-5 w-full text-black rounded-xl shadow-sm bg-o2o-gris-arena h-[200px]" >
                                    <div className="flex gap-5">
                                        <img
                                            src={data.usuario?.perfilImg || `${imgUrl}/perfil-adolfo.png`}
                                            alt="profile"
                                            className="w-16 h-16 rounded-full border-2 border-white shadow"
                                        />
                                        
                                        <div className="flex flex-col shrink-0 my-auto w-full">
                                            <div className="text-2xl font-bold">{data.usuario?.informacion?.nombre}</div>
                                            <div className="text-sm leading-4">{data.fecha}</div>
                                        </div>
                                    </div>
                                    <div className="mt-3 text-base leading-6">
                                    {data.testimonial}
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        ) )}
                    </Swiper>
                </div>
            </div>
        </div>
        </>
    )
}

export default BlogSlides
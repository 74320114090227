import React, { useState, useEffect } from "react";
import Servicios from "../Servicios";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { useNavigate, useParams } from "react-router-dom";
import StarRating from "../../../components/CalificacionEstrellas";
import { imgUrl } from "../../../helpers/recursosurl";
import MisServicios from "../../enfermero/mis_servicios/MisServicios";
import { Link } from "react-router-dom";
import { calificacionesResource, getAuthUser, serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import { FormatCurrency } from "../../../helpers/CurencyFormat";
import CalificacionPrimerdia from "./CalificacionEnviada";


const Calificaciones = ({ id, setComponent, action, servicio }) => {
    const [calificables, setCalificables] = useState([])
    const [global, setGlobal] = useState([])
    const [loader, setLoader] = useState({ loading: false, textCarga: 'Enviando evaluacion.' })
    const authUser = getAuthUser()
    const [plataforma, setPlataforma] = useState(false);
    const [plataformaGlobal, setPlataformaGlobal] = useState(0)
    const [textPlataforma, setTextPlataforma] = useState('')

    useEffect(() => {
        const getCatalogos = async () => {
            const response = await calificacionesResource('get', { params: { model: action, insignia: false } })
            setCalificables(response.calificables)
            setGlobal(response.calificables_global)
        }
        getCatalogos()
    }, []);

    const changeCalificacion = (value, id, globalVal = false) => {
        if (!globalVal) {
            let newArray = calificables.map((c) => {
                if (c.id == id) {
                    c.calificacion = value
                }
                return c;
            })
            setCalificables(newArray)
        } else {
            if (plataforma == true) {
                setPlataformaGlobal(value)
            } else {
                setGlobal({ ...global, calificacion: value })
            }

        }
    }

    const sendEvaluacion = async (model = false) => {
        setLoader({ ...loader, loading: true })
        let data = {
            calificables: [...calificables],
            global_calificacion: (action == 'Servicio') ? 0 : global.calificacion,
            global_id: action == ('Servicio') ? 0 : global.id,
            servicio_id: servicio.id,
            action: plataforma ? 'one2one' : action,
            cita_id: servicio.cita_activa?.id,
            global: plataformaGlobal,
            textPlataforma: textPlataforma
        }
        const response = await calificacionesResource('post', data)
        if (response.status) {
            if (plataforma == true) {
                setComponent(false)
            }
            if (model) {
                const calific = await calificacionesResource('get', { params: { model: 'one2one', insignia: false } })
                setPlataforma(true)
                setCalificables(calific.calificables)
                setGlobal(calific.calificables_global)
            } else {
                setComponent(<CalificacionPrimerdia servicio={servicio} setComponent={setComponent} />)
            }

            setLoader({ ...loader, loading: false })
        }
        setLoader({ ...loader, loading: false })
    }
    return (
        <>
            <div className="flex flex-wrap max-w-full lg:max-w-[75%] mx-auto px-4 mt-10">
                <div className="w-full flex flex-col items-start">
                    <div className="w-full md:w-4/5">
                        <div className="flex justify-between items-center mb-[18px]">
                            <CustomButtonOval className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover" onClick={() => setComponent(false)}>
                                <svg
                                    width="7"
                                    height="10"
                                    viewBox="0 0 7 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.80078 1L1.00078 5L5.80078 9"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                &nbsp;&nbsp;Regresar a solicitudes de servicio
                            </CustomButtonOval>
                        </div>
                    </div>
                </div>
                <div className="text-[24px] mb-[18px] font-semibold w-full">
                    <h3>{action == 'Servicio' ? 'Review de cierre del servicio' : 'Review del primer día de servicio'}</h3>
                </div>

                <div className="border rounded-lg p-4 mb-4 bg-o2o-gris-calido-hover w-full">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="text-base font-semibold mb-2 lg:mb-0">
                            {authUser.perfil_id === 4 ? (
                                <>Servicio de {servicio.administrador?.nombre} {servicio.administrador?.apellidos} para:</>
                            ) : (
                                <>Servicio de {servicio.enfermero?.nombre} {servicio.enfermero?.apellidos}:</>
                            )}
                        </div>
                        <div className="flex justify-between items-center w-full lg:w-auto">
                            <p>{servicio.folio}</p>
                            <CustomButtonOval className="flex justify-end bg-o2o-secondary-rojo text-sm hover:bg-o2o-secondary-rojo-hover ml-4">
                                Reportar
                            </CustomButtonOval>
                        </div>
                    </div>
                    <hr className="border-gray-300 my-4" />
                    <div className="flex flex-col lg:flex-row justify-between">
                        <div className="lg:w-1/2 flex flex-col lg:flex-row lg:mr-4 mb-4 lg:mb-0">
                            <div className="bg-[#f1ece9] rounded-full w-[72px] h-[72px] flex items-center justify-center mr-5 mb-5 lg:mb-0">
                                <img
                                    loading="lazy"
                                    srcSet={(authUser.perfil_id === 4 ? servicio.paciente?.perfilImg : servicio.enfermero?.perfilImg) || `${imgUrl}/user.png`}
                                    className="rounded-full shrink-0 max-w-full aspect-square w-[164px]"
                                />
                            </div>
                            <div className="flex flex-col">
                                <div className="text-sm font-semibold mb-3 flex items-center space-x-2">
                                    <p className="mr-2">{servicio.paciente?.nombre} {servicio.paciente?.apellidos}</p>
                                    <p>{servicio.paciente?.edad} años</p>
                                </div>
                                <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena border-solid">
                                    <div className="text-sm font mb-[18px]">
                                        {servicio.paciente?.diagnosticoM}
                                    </div>
                                    <div className="text-sm font mb-2">
                                        <StarRating rating={servicio.paciente?.calificacion} color={'#FFA000'} estatico={true} />
                                    </div>
                                    <div className="flex space-x-2">
                                        <Link className="justify-center px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50 mr-3 mb-2 bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover" to={`/paciente-solicitud/${servicio.paciente?.id}/${servicio.id}`}>Ver perfil completo</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col items-center lg:items-start lg:mr-4 mb-4 lg:mb-0">
                            <div className="w-full px-2 mb-3 border-t border-o2o-gris-arena border-solid">
                                <div className="text-sm font-semibold">
                                    Periodo del Servicio
                                </div>
                                <div className="text-sm font">
                                    <p>{servicio?.fecha_inicial} al {servicio?.fecha_fin}</p>
                                    <p>{servicio?.turno?.nombre}</p>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col items-center lg:items-start">
                            <div className="text-sm font-semibold">
                                Contacto
                            </div>
                            <div className="text-sm font">
                                <p>{servicio.paciente?.email}</p>
                                <p>{servicio.paciente?.movil}</p>
                            </div>
                        </div>
                    </div>
                    <hr className="border-gray-300 my-4" />
                    <div className="flex flex-col lg:flex-row justify-between mt-4">
                        <div className="flex flex-col flex-grow items-center lg:items-start ml-[18px] mr-[12px]">
                            <div className="text-sm font">Costo total del servicio</div>
                            <div className="text-sm font">{FormatCurrency(servicio.pagos?.costo_total)}</div>
                        </div>
                        {authUser.perfil_id === 4 &&
                            <>
                                <div className="flex flex-col flex-grow items-center lg:items-start mr-[8px]">
                                    <div className="text-sm font">Pago servicios One 2 One</div>
                                    <div className="text-sm font">{FormatCurrency(servicio.pagos?.comision)}</div>
                                </div>
                                <div className="flex flex-col flex-grow items-center lg:items-start mr-[200px] lg:mr-0">
                                    <div className="text-base font-semibold">Tus honorarios</div>
                                    <div className="text-base font-semibold">{FormatCurrency(servicio.pagos?.sin_comision)}</div>
                                </div>
                            </>
                        }
                    </div>
                </div>

                <div className="text-base font-semibold w-full">
                    {action == 'Servicio' ?
                        <>
                            {plataforma ?
                                'Califica los servicios generales de nuestra plataforma'
                                :
                                `Califica las siguientes características`
                            }

                        </>
                        :
                        `Califica las siguientes características de ${servicio.paciente?.nombre} ${servicio.paciente?.apellidos}`
                    }

                </div>
                <div className="text-xs w-full">
                    Usa las estrellas para definir el grado de satisfacción con el cliente. Siendo 1 la mínima calificación y 5 la máxima.
                </div>

                <div className="p-4">
                    <div className="flex flex-wrap gap-3 justify-center">
                        {calificables.map((c, index) => (
                            <div
                                className="flex flex-col justify-between items-start h-48 w-48 border border-o2o-gris-calido text-black p-4 rounded-md relative"
                                key={index}
                            >
                                <div className="text-sm font-semibold w-full">
                                    {c.nombre}
                                </div>
                                <div className="text-xs w-full mt-[6px] flex-grow"> {/* Añadir padding-bottom aquí */}
                                    {c.descripcion}
                                </div>
                                <div className="absolute bottom-0 left-0 right-0 w-full px-4"> {/* Posiciona las estrellas */}
                                    <StarRating rating={c.calificacion} setRating={changeCalificacion} id={c.id} />
                                </div>
                            </div>
                        ))}






                    </div>
                </div>
                {action != 'Servicio' &&
                    <>
                        
                    </>
                }
                {(action == 'Servicio' && !plataforma) &&
                    <div className={`border rounded-lg p-4 mb-4  w-full mt-[94px] ${!plataforma ? 'bg-o2o-gris-calido-hover' : ''} `}>
                        <>
                            <div className="text-base font-semibold">
                                Escribe un testimonial sobre tu experiencia con {authUser.perfil_id === 4 ? servicio.paciente?.nombre:servicio.enfermero?.nombre}
                            </div>
                            <textarea
                                className="border rounded-lg bg-o2o-gris-calido-hover w-full h-32 p-2  resize-none"
                                placeholder="Compártenos tu experiencia utilizando nuestra plataforma"
                                value={textPlataforma}
                                onChange={(e) => setTextPlataforma(e.target.value)}
                            ></textarea>
                        </>
                    </div>
                }
                <div className={`border rounded-lg p-4 mb-4  w-full mt-[94px] ${!plataforma ? 'bg-o2o-gris-calido-hover' : ''} `}>
                    {(plataforma) &&
                        <>
                            <div className="text-base font-semibold">
                                Escribe un testimonial sobre el servicio que One 2 One ofrece
                            </div>
                            <textarea
                                className="border rounded-lg bg-o2o-gris-calido-hover w-full h-32 p-2  resize-none"
                                placeholder="Compártenos tu experiencia utilizando nuestra plataforma"
                                value={textPlataforma}
                                onChange={(e) => setTextPlataforma(e.target.value)}
                            ></textarea>
                        </>
                    }

                    {(action != 'Servicio') &&
                        <div className="text-base font-semibold">
                            Evalúa el desempeño global del primer día de {servicio.paciente?.nombre} y {servicio.administrador?.nombre}.
                        </div>
                    }
                    {(plataforma == true) &&
                        <div className="text-base font-semibold">
                            Evalúa el desempeño global de One 2 One Nurses
                        </div>
                    }
                    <div className="mt-[18px] flex flex-col lg:flex-row justify-between items-center">
                        {(action != 'Servicio') &&
                            <div className="flex items-center space-x-4">
                                <StarRating rating={global.calificacion} color={'#FFA000'} setRating={changeCalificacion} id={global.id} global={true} />
                            </div>
                        }
                        {(plataforma == true) &&
                            <div className="flex items-center space-x-4">
                                <StarRating rating={plataformaGlobal} color={'#FFA000'} setRating={changeCalificacion} id={15} global={true} />
                            </div>
                        }

                        <div className="flex flex-col bg-o2o-gris-calido-hover lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2 w-full justify-center">
                            {action != 'Servicio' ?
                                <>
                                    <CustomButtonOval loading={loader.loading} textCarga={loader.textCarga} onClick={() => sendEvaluacion()} className="bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover">Enviar evaluación del primer día</CustomButtonOval>
                                    <CustomButtonOval className="bg-gris-arena-button text-sm hover:bg-gris-arena-hover">Reportar perfil</CustomButtonOval>
                                </>
                                :
                                <>
                                    {!plataforma && <CustomButtonOval loading={loader.loading} textCarga={loader.textCarga} onClick={() => sendEvaluacion('One2one')} className="bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover">Calificar y continuar</CustomButtonOval>}
                                </>
                            }
                        </div>
                    </div>
                </div>
                {plataforma &&
                    <div className="flex flex-col bg-o2o-gris-calido-hover lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2 mt-4 lg:mt-4 p-3 w-full justify-center">
                        <CustomButtonOval loading={loader.loading} textCarga={loader.textCarga} onClick={() => sendEvaluacion('One2one')} className="bg-o2o-aqua-hover text-sm hover:bg-o2o-aqua-hover">Calificar y continuar</CustomButtonOval>
                    </div>
                }

            </div>
        </>
    );
}
export default Calificaciones;
import React, { useState, useEffect } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BarraBusquedaPublico from "./BarraBusquedaPublico";

const ResultadosPublico = () => {
    const config = useSelector((state) => state.configZendesk.zendeskConfig);
    const {enfermeros,meta} = useSelector((state) => state.BusquedasPublicas);
    const navigate = useNavigate()

    return (
        <>
            <div className="pl-8 p-4 mt-[50px] w-full rounded-none max-md:pl-5 max-w-[96%] mr-[60px]">
                <div className="flex flex-col mt-10 lg:flex-row">
                    <BarraBusquedaPublico />
                    <div className="p-2">
                        <h1 className="text-2xl font-bold mb-4">
                            Inicia sesión para continuar
                        </h1>
                        <div className="bg-white p-6 border rounded-xl mb-4">
                            <h2 className="text-xl font-semibold mb-2">
                                Para ver los perfiles de enfermeros para el cuidado de tu paciente es necesario crear una cuenta o iniciar sesión.
                            </h2>
                            <div className="flex flex-wrap space-x-4">
                                <CustomButtonOval
                                    type={'success'}
                                    onClick={() => navigate("/login")}
                                    className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover mb-2'
                                >
                                    Iniciar sesión
                                </CustomButtonOval>
                                <CustomButtonOval
                                    type={'success'}
                                    onClick={() => navigate("/registro/1")}
                                    className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover mb-2'
                                >
                                    Crear cuenta
                                </CustomButtonOval>
                            </div>

                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-1/2 p-2">
                                <p>
                                    <b>Después de aceptar la solicitud de servicio, en un lapso máximo de 24 horas recibirás la confirmación</b> de alguno de ellos para ofrecerte el servicio de cuidados enfermeros.
                                </p>
                            </div>
                            <div className="w-full lg:w-1/2 p-2">
                                <p>
                                    <b>Recibirás un SMS en tu móvil,</b> además de un mensaje en tu perfil de One 2 One (pestaña «historial de servicios {'>'} solicitudes enviadas»). Sugerimos estar atento de tu dispositivo o del sitio web.
                                </p>
                            </div>
                        </div>
                        <div className='w-full border-t border-o2o-gris-arena border-solid mb-3'></div>
                        <div className="flex flex-col mt-4 p-2 bg-nefritas rounded-lg">
                            <p className="font-bold mb-2">Mientras tanto puedes:</p>
                            <ul className="list-disc pl-5">
                                <li>Ver el resumen del perfil de nuestros profesionistas para conocerlos.</li>
                                <li>Filtrar tu búsqueda para mejorar las características del perfil que te atenderá.</li>
                            </ul>
                        </div>
                        <div className='w-full border-t border-o2o-gris-arena border-solid mb-3'></div>
                        <div className="flex flex-wrap items-center justify-between mb-1">
                            <h3 className="text-2xl font-bold">Resultados de la búsqueda</h3>
                        </div>
                        <div className='w-full border-t border-o2o-gris-arena border-solid mb-3'></div>

                        <div className="container mx-auto p-0">
                            <div className="flex flex-wrap -mx-2 bg-gradient-to-r from-gray-200 via-transparent to-gray-200">

                                {enfermeros.length==0 ? 
                                    <div className="bg-white rounded-lg shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)] p-4 w-full sm:w-1/2 lg:w-[32.4%] relative border border-gray-300 m-1 mb-5 flex flex-col">
                                        Realiza una búsqueda para ver los perfiles que tenemos para ti
                                    </div>
                                :
                                enfermeros?.map((e, index) => (
                                    <div className="bg-white rounded-lg shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)] p-4 w-full sm:w-1/2 lg:w-[32.4%] relative border border-gray-300 m-1 mb-5 flex flex-col" key={index}>

                                        <div className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 bg-white rounded-full p-2 shadow-md">

                                        </div>
                                        <img src={e.perfilImg || 'https://placehold.co/400x300'} className="w-full rounded-lg" />
                                        <div className="flex justify-between items-center mt-2">
                                            <h3 className="text-xl font-bold">{e.nombre} {e.apellidos}</h3>
                                            <span className="text-gray-600 flex items-center">
                                                <svg className="w-6 h-6 text-o2o-secondary-mostaza ms-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                                </svg>
                                                {e.calificacion}
                                            </span>
                                        </div>
                                        <p className="text-gray-600">{e.edad} años</p>
                                        <p className="mt-2 font-semibold">{e.acreditaciones?.estudios_nombre} en enfermería</p>
                                        <p className="text-gray-600">Cédula: {e.acreditaciones?.cedula_profecional || 'N/A'}</p>
                                        <p className="mt-2 font-semibold">Con especialidad en:</p>
                                        <div className="flex flex-col space-y-2 mt-1">
                                            {e.acreditaciones?.formacion_especialidades?.map((esp, index) => (
                                                <CustomButtonOval
                                                    key={index}
                                                    type={'success'}
                                                    className='bg-o2o-gris-arena text-sm w-full'
                                                >
                                                    {esp.especialidad_nombre},
                                                </CustomButtonOval>
                                            ))}
                                        </div>
                                        <div className='w-full border-t border-o2o-gris-arena border-solid mb-3 mt-3'></div>
                                        <p className="mt-2 text-gray-700">
                                            {e.sexo_id == 2 ? 'Enfermera especializada en: ' : 'Enfermero especializado en: '}
                                            {e.acreditaciones?.servicios?.map((s, index) => (
                                                <span key={index}>
                                                    {` ${s.nombre}${index === e.acreditaciones.servicios.length - 1 ? '.' : ','}`}
                                                </span>
                                            ))}

                                        </p>
                                        <div className='w-full border-t border-o2o-gris-arena border-solid mb-3 mt-3'></div>
                                        <div className="mt-auto flex justify-between items-center">
                                            <CustomButtonOval
                                                type={'success'}
                                                disabled={true}
                                                className='text-sm bg-o2o-aqua hover:bg-o2o-aqua-hover w-full lg:w-[90%]'
                                            >
                                                Ver perfil detallado
                                            </CustomButtonOval>
                                        </div>
                                        <div className="mt-auto flex justify-between items-center">
                                            <CustomButtonOval
                                                type={'success'}
                                                disabled={true}
                                                className='text-sm bg-o2o-aqua hover:bg-o2o-aqua-hover w-full lg:w-[90%]'
                                            >
                                                Solicitar servicio
                                            </CustomButtonOval>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>

                        <div className="mt-8">
                            <h2 className="text-xl font-bold mb-2">Somos una comunidad segura</h2>
                            <p className="mb-4 text-gray-700 text-base">Consulta nuestros protocolos</p>
                            <div className="flex flex-wrap space-x-4">
                                <CustomButtonOval
                                    type={'success'}
                                    onClick={() => window.open(config.terminos_condiciones?.url, "_blank")}
                                    className='bg-o2o-gris-arena-button text-sm hover:bg-o2o-aqua-hover mb-2'
                                >
                                    Términos y condiciones
                                </CustomButtonOval>
                                <CustomButtonOval
                                    type={'success'}
                                    onClick={() => window.open(config.codigo_conducta?.url, "_blank")}
                                    className=''
                                >
                                    Codigo de conducta
                                </CustomButtonOval>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResultadosPublico
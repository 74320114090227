import React, { useState } from 'react';
import { dangerToast, warningToast } from '../../helpers/Toast'

const FileInput = ({ onChange, format,nameInput='Seleccionar archivo',withClass='w-full',textButton=true}) => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Verificar si el archivo es de un formato permitido
    if (!validateFormat(file)) {
      dangerToast('Solo puedes seleccionar archivos formato: ' + (format=='img' ? 'png, jpeg, jpg':format));
      return;
    }

    // Verificar el tamaño del archivo
    if (!validateSize(file)) {
      dangerToast('Archivios maximo de 1MB');
      return;
    }

    setFileName(file.name);
    onChange(file);
  };

  // Función para validar el formato del archivo
  const validateFormat = (file) => {
    if (format === 'pdf') {
      return file.type === 'application/pdf';
    } else if (format === 'img') {
      return file.type == 'image/png'||file.type == 'image/jpeg'||file.type == 'image/jpg';
    }else if(format=='xml'){
      return file.type === 'text/xml';  
    }

    return false;
  };

  // Función para validar el tamaño del archivo
  const validateSize = (file) => {
    if (file.size <= 1024 * 1024) {
      return true
    }

    return false
  };

  return (
    <div className={`flex items-center justify-center ${withClass}`}>
      <label className="flex flex-col items-center w-full">
        <span className="px-4 py-2 bg-o2o-gris rounded-full shadow-lg cursor-pointer hover:bg-o2o-gris-hover">
          {fileName ? (textButton ? fileName:nameInput) : nameInput}
        </span>
        <input
          type="file"
          className="hidden"
          onChange={handleFileChange}
        />
      </label>
    </div>
  );
};

export default FileInput;
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import CustomInput from "../../../components/inputs/CustomInput";
import { bancariosResource, getAuthUser, getEntidades, usuariosResource } from "../../../helpers/configRoutes/backend_helper";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { validaNames, validaTarjeta, validaVencimiento } from "../../../helpers/formularios/Validation";
import CustomInputMaskPerson from "../../../components/inputs/CustomInputMaskPerson";
import CustomSelect from "../../../components/inputs/CustomSelect";
import { showFormErrors } from "../../../helpers/showErrors";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe((process.env.REACT_APP_STRIPE_PUBLIC || 'pk_test_51QKsgXJ7bvcjvRG5IFNF8BQSKFqROHTbcFcuPDaBtQ6jqG2UuAKlzPviBLR1y2LbDZgHv9QdipxPWjAJMPHHDEPJ00mmo68qZ4'));

const NuevoMetodoCobro = () => {
    const {id} = useParams()
    const stripe = useStripe();
    const elements = useElements();
    const [datoBancario,setDatoBancario] = useState({
        tarjeta_nombre:'',
        tarjeta:'',
        vencimiento:'',
        cvv:'',
        cvvConfirm:'',
        principal:false
    })
    const [loader,setLoader]=useState(false)
    const navigate = useNavigate()
    let authUser = getAuthUser();
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: datoBancario,
        validationSchema: Yup.object({
            tarjeta_nombre: validaNames(),
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoader(true)

            if (!stripe || !elements) {
                return;
            }

            const cardElement = elements.getElement(CardElement);

            // Crear el método de pago en Stripe
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: {
                    name: values.tarjeta_nombre,
                },
            });

            if (error) {
                setErrors({ tarjeta: `Error al generar el método de pago: ${error.message}` });
                setLoader(false)
                return false;
            } 
            values.token= paymentMethod.id
            const response = await bancariosResource(values.id ? 'put':'post', values,id);
            
            if (response.status) {
                navigate('/informacion-cobro')
            }
            setLoader(false)
        },
    });

    const [bancarias, setBancarias] = useState([])
    const [financieras, setFinancieras] = useState([])
    

    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getEntidades()
            setBancarias(response.entidadesBancarias)
            setFinancieras(response.entidadesFinancieras)
        }
        getCatalogos()
    }, []);


    useEffect(() => {
        const getDatosB = async () => {
            const response = await bancariosResource('show', {},id);
            if (response.status) {
                Object.keys(response.bancarioDato).forEach(field => {
                    validation.setFieldValue(field, response.bancarioDato[field], false); // Setear valores en el formik sin disparar validación
                });
            }
        }
        if (id) {
            getDatosB();
        }
    }, [id]);
    
    return (
        <>
            <div className="flex flex-col max-w-full lg:max-w-[75%] ml-auto mr-auto px-4 lg:ml-[19%] lg:mr-[60px]">
                <div className="flex flex-wrap max-w-full md:max-w-[75%] ml-4 p-4">
                    <div className="w-full mb-4">
                        <b className="block mb-2 text-xl">Esta es tu central de información.</b>
                        <p>Aquí puedes consultar y editar tus datos para conectar con especialistas en enfermería.</p>
                    </div>
                </div>
                <div className="flex justify-between items-center mb-4">
                    <CustomButtonOval className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover" onClick={() => (navigate('/informacion-cobro'))}>
                        <svg
                            width="7"
                            height="10"
                            viewBox="0 0 7 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.80078 1L1.00078 5L5.80078 9"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        &nbsp;&nbsp;{'Regresar a informacion de cobro'}
                    </CustomButtonOval>
                </div>
                <div className="flex justify-between items-start ml-4 text-[24px]">
                    <div className="font-bold">
                        <span>Agregar nuevo método de pago</span>
                        <p className="text-sm font-normal">Ingresa tus datos de cobro</p>
                    </div>
                    <CustomButtonOval className={`flex items-center ${validation.values.principal? 'bg-o2o-gris-calido-hover':'bg-o2o-gris-calido'} text-sm hover:bg-o2o-gris-calido-hover`} onClick={()=>validation.setFieldValue('principal',!validation.values.principal)}>
                        {validation.values.principal ? 'Establecer como método de pago secundario':'Establecer como método de pago principal'}
                    </CustomButtonOval>
                </div>



                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        showFormErrors(validation);
                        return false;
                    }}
                    className="flex flex-wrap"
                >
                    




                    <div className="flex flex-wrap max-w-full px-4 mt-10">
                    <div className="w-full text-base font-bold leading-6 mb-2">Método principal de Pago</div>
                    <div className="w-full px-2 mb-3 border-t border-black border-solid"></div>
                    
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <label>Nombre en la tarjeta</label>
                        <div className="flex flex-col w-full py-1">
                        <input
                            type="text"
                            name="tarjeta_nombre"
                            placeholder="Nombre"
                            value={validation.values.tarjeta_nombre || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            className="w-full py-1.5 px-2.5 rounded border focus:outline-none focus:border-o2o-aqua-hover bg-stone-100 text-ellipsis" // Puedes ajustar esto según tus estilos
                        />
                        </div>
                    </div>

                    {/* Componente de Stripe para la Tarjeta */}
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <label>Información de la Tarjeta</label>
                        <CardElement className="w-full py-1.5 px-2.5 rounded border focus:outline-none focus:border-o2o-aqua-hover bg-stone-100 text-ellipsis" />
                    </div>

                    {/* Selección de entidad bancaria */}
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <label>Entidad Bancaria</label>
                        <CustomSelect
                            name="entidad_id"
                            options={[{ label: 'Seleccione una opción', value: 0 }, ...bancarias.map(data => ({ value: data.id, label: data.nombre }))]}
                            value={validation.values.entidad_id || ''}
                            error={validation.errors.entidad_id}
                            onChange={validation.handleChange}
                        />
                    </div>

                    {/* Selección de entidad financiera */}
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <label>Entidad Financiera</label>
                        <CustomSelect
                            name="entidad_financiera"
                            options={[{ label: 'Seleccione una opción', value: 0 }, ...financieras.map(data => ({ value: data.id, label: data.nombre }))]}
                            value={validation.values.entidad_financiera || ''}
                            error={validation.errors.entidad_financiera}
                            onChange={validation.handleChange}
                        />
                    </div>
                </div>









                    <div className="w-full flex justify-center mt-6">
                        <CustomButtonOval loading={loader} textCarga={'Guardando Datos'} type={'submit'} className="bg-o2o-aqua text-sm hover:bg-o2o-aqua-hover">
                            Guardar
                        </CustomButtonOval>
                    </div>
                </form>

            </div>
        </>
    )

}
const WrappedCobro = (props) => (
    <Elements stripe={stripePromise}>
        <NuevoMetodoCobro {...props} />
    </Elements>
);

export default WrappedCobro;
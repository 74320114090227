import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { reset_usuario } from '../../store/users/registro/action'
import { getAuthUser } from '../../helpers/configRoutes/backend_helper'

const Fotter = () => {
  const config = useSelector((state) => state.configZendesk.zendeskConfig);
  const authUser = getAuthUser()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();

  const getYear = () => {
    return new Date().getFullYear();
  }

  const goRegistro = (action) => {
    dispatch(reset_usuario())
    navigate(`/registro/${action}`)
  }

  return (
    <div className={`flex ${authUser?.id ? 'md:ml-[19%]' : ''} z-[232] flex-col w-full max-w-o2o mx-auto px-6 py-6 m5 ${location.pathname === '/login' ? 'rounded-b-xl' : 'rounded-xl'} bg-stone-200  mb-1 ${location.pathname === '/login' ? 'mt-0' : (location.pathname === '/mensajes' ? 'mt-0' : 'mt-10')}`}>
      <div className="flex flex-wrap gap-4 content-start pr-5 text-sm leading-4 text-black max-md:pr-5">
        <div className="flex flex-col flex-1 pr-4 whitespace-nowrap">
          <div className="font-bold">Empresa</div>
          <Link className="mt-1.5" to="/quienes-somos">Quiénes somos</Link>
          <Link className="mt-1.5" to="/como-funciona">Cómo funciona</Link>
          <Link className="mt-1.5" to="/costos-membresias">Costos y membresía</Link>
          <Link className="mt-1.5" to="/testimoniales">Testimoniales</Link>
        </div>
        <div className="flex flex-col flex-1 pr-4 whitespace-nowrap">
          <div className="font-bold">Conecta</div>
          <button className="mt-1.5 text-left" onClick={() => navigate('/construccion-public')}>Busca un enfermero</button>
          <button className="mt-1.5 text-left  max-md:mr-2.5" onClick={() => goRegistro(1)}>Crea tu perfil de cliente</button>
          <button className="mt-1.5 text-left" onClick={() => goRegistro(2)}>Crea tu perfil profesional</button>
        </div>
        <div className="flex flex-col flex-1 pr-4 whitespace-nowrap">
          <div className="font-bold">Centro de atención</div>
          <div className="mt-1.5 cursor-pointer" onClick={() => window.open(process.env.REACT_APP_ZENDESK_URL, '_blank')}>Soporte</div>
          <div className="mt-1.5 cursor-pointer" onClick={() => window.open(config.codigo_conducta?.url, '_blank')}>Código de conducta</div>
          <div className="mt-1.5 cursor-pointer" onClick={() => window.open(config.terminos_condiciones?.url, '_blank')}>Términos y condiciones</div>
          <div className="mt-1.5 cursor-pointer" onClick={() => window.open(config.preguntas_frecuentes?.url, '_blank')}>FAQs</div>
        </div>
        <div className="flex flex-col flex-1 pr-4 whitespace-nowrap">
          <div className="font-bold">Legal</div>
          <div className="mt-1.5 cursor-pointer" onClick={() => window.open(config.aviso_privacidad?.url, '_blank')}>Aviso de privacidad</div>
          <div className="mt-1.5 cursor-pointer" onClick={() => window.open(config.terminos_condiciones?.url, '_blank')}>Términos y condiciones</div>
          <div className="mt-1.5 cursor-pointer" onClick={() => window.open(config.prestacion_servicios?.url, '_blank')}>Contrato de prestación de servicios</div>
        </div>
        <div className="flex flex-col flex-1 pr-4 whitespace-nowrap">
          <div className="font-bold">Comunidad</div>
          <div className="mt-1.5 cursor-pointer" onClick={() => navigate('/seguridad')}>Seguridad</div>
          <div className="mt-1.5 cursor-pointer" onClick={() => window.open(config.blog?.url, '_blank')}>Blog</div>
        </div>
      </div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a2e77bf7b63ce1d03e1d3784af6b67ba06c41f82ed278bed49280dfd9b091b9b?apiKey=488c1b104d124b2d9cec11a2285ec3be&"
        className={location.pathname === '/mensajes' ? "mt-2 max-w-full aspect-[3.23] w-[137px] max-md:mt-10" : "mt-11 max-w-full aspect-[3.23] w-[157px] max-md:mt-10"}
      />
      <div className={location.pathname === '/mensajes' ? "flex gap-5 justify-between mt-1 max-md:flex-wrap max-md:mt-2" : "flex gap-5 justify-between mt-11 max-md:flex-wrap max-md:mt-10"}>
        <div className="flex-auto text-xs leading-3 text-black max-md:max-w-full">
          One 2 One Nurses es una marca de Escuela de Enfermería Angelópolis.
          Puebla, México.
          <br />
          Todos los derechos reservados. {getYear()}
        </div>
        <div className="flex gap-3 justify-end">
          <Link to='https://www.instagram.com/one2onenurses?igsh=eXY3NnNkOGtiZTJ4' target='_blank'>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a3aa3e1a50259fad2f17b9b206aa6929ea0831190d8390949e3b65f4ac4d6fc?apiKey=488c1b104d124b2d9cec11a2285ec3be&"
              className="shrink-0 w-8 aspect-square"
            />
          </Link>

          <Link to='https://x.com/121Nurses' target='_blank'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="shrink-0 w-8 aspect-square">
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
            </svg>
          </Link>
          <Link to='https://www.facebook.com/share/1r6qhvcJYGgVrz3X/?mibextid=qi2Omg' target='_blank'>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/91b4448896e9ad4e635ff8e8a62eaf1edf44c44ff97e2557ed55511b4a22e1e7?apiKey=488c1b104d124b2d9cec11a2285ec3be&"
              className="shrink-0 w-8 aspect-square"
            />
          </Link>
          <Link to='https://www.tiktok.com/discover/escuela-de-enfermer%C3%ADa-angelopolis-puebla' target='_blank'>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f48c4863dd5ce0781d6dbcf2e3c7f364d5a2e8aa6a7193a433ee2cf29011eecb?apiKey=488c1b104d124b2d9cec11a2285ec3be&"
              className="shrink-0 w-8 aspect-square"
            />
          </Link>
        </div>
      </div>
    </div>

  )
}

export default Fotter
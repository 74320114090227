import { SET_RESULTADOS,BACK_RESULTADOS } from "./actionTypes";

const initialState = {
    back_resultados: false,
    enfermeros: [],
    meta: {}
};

const busquedasPublicas = (state = initialState, action) => {
    switch (action.type) {
        case SET_RESULTADOS:
            // Actualizar el estado
            const newState = {
                ...state,
                enfermeros: action.payload
            };
            return newState;
        case BACK_RESULTADOS:
            const newSt = {
                ...state,
                back_resultados: action.payload
            };
            return newSt;
        default:
            return state;
    }
};

export default busquedasPublicas;

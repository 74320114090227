import React from 'react'
import { InfoBlock } from '../../../components/InfoBlock'
import { infoBlocks, infoBlocks2 } from '../../../helpers/recursos'

const Garantias = () => {

  return (
    <>
      <div className="flex flex-wrap px-4 pb-10 rounded-2xl shadow-sm bg-stone-100 mr-[60px]">
        <div className="w-full md:w-full">
          <header className="pb-8 border-b border-solid border-zinc-400 md:max-w-full mt-10 sm:mt-0 mx-[60px]">
            <div className="flex justify-between">
              <h2 className="text-3xl font-bold leading-9 text-black md:mt-10 w-[255px]">
                Garantías para clientes y pacientes
              </h2>
              <h2 className="text-3xl font-bold leading-9 text-black md:mt-10 w-[255px]">
                Garantías para profesionales
              </h2>
            </div>
          </header>

          {/* Contenedor para distribuir los InfoBlocks en 2 columnas */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-[60px] mt-6">
            {infoBlocks.map((block, index) => (
              <InfoBlock key={index} {...block} />
            ))}
            <div></div>
            {infoBlocks2.map((block, index) => (
              <InfoBlock key={index} {...block} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Garantias
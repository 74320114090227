import React, { useEffect, useState } from "react";
import CustomButtonOval from "../../components/buttons/CustomButtonOval";
import { facturasResource, serviciosResource } from "../../helpers/configRoutes/backend_helper";
import PreviewPDF from "../../components/modals/PreviewPdf";
import { useNavigate } from "react-router-dom";

const ServiciosFacturar = () => {
    const navigate = useNavigate()
    const [servicios, setSerficios] = useState([])
    const [facturas, setFacturas] = useState([])
    const [montoFacturado, setMontoFacturado] = useState(0)
    const [modalFactura, setModalFactura] = useState({ show: false, url: '' })

    useEffect(() => {
        const getFacturas = async () => {
            const response = await facturasResource('get', {});
            setFacturas(response.facturas);
            setMontoFacturado(response.monto)

            const responseServicios = await serviciosResource('get', {});
            const serviciosFacturar = responseServicios.servicios.filter((s) => s.montoAfacturar);
            setSerficios(serviciosFacturar);
        }

        getFacturas();
    }, []);

    return (
        <div className="w-full px-4 ml-0 md:ml-[19%] max-w-full md:max-w-[75%]">
            {servicios.length > 0 &&
                <h3 className="text-xl font-semibold mb-4">Turnos por facturar</h3>
            }
            
            {servicios.map((servicio, i) => (
                <div key={i} className="p-5 mb-5 flex flex-col md:flex-row items-start w-full border-t-4 border-b-4 rounded-[18px] border-o2o-gris-arena">
                    <p className='text-base font-bold w-full md:w-1/5 mb-4 md:mb-0'>Servicio #{servicio.folio}</p>
                    <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-4">
                        <div>
                            <p><strong>Cliente:</strong> {servicio.administrador.nombre} {servicio.administrador.apellidos}</p>
                            <p><strong>Paciente:</strong> {servicio.paciente.nombre} {servicio.paciente.apellidos}</p>
                        </div>
                        <div>
                            <p><strong>Servicio:</strong> {servicio.folio}</p>
                            <p><strong>Estatus:</strong> {servicio.estatus.nombre}</p>
                        </div>
                        <div>
                            <p>
                                <strong>Conceptos de facturación:<br /></strong>
                                {servicio.citasFacturar.map((c, i) => (
                                    <span key={i}>Cita #{c.cita_no} Monto ${servicio.pagoCita}<br /></span>
                                ))}
                            </p>
                        </div>
                        <div>
                            <p>
                                <strong>Pago por servicio:</strong> ${servicio.pagos.sin_comision}
                            </p>
                            <p>
                                <strong>Monto A facturar:</strong> ${servicio.montoAfacturar}
                            </p>
                            <CustomButtonOval
                                className={'w-full text-center bg-o2o-aqua hover:bg-o2o-aqua-hover mt-2 md:mt-0'}
                                onClick={() => navigate(`/factura-enfermero/${servicio.id}`)}
                            >
                                Facturar
                            </CustomButtonOval>
                        </div>
                    </div>
                </div>
            ))}

            <h3 className="text-xl font-semibold mb-4">Turnos facturados</h3>
            {facturas.map((f, index) => (
                <div key={index} className="p-5 mb-5 flex flex-col md:flex-row items-start w-full border-t-4 border-b-4 rounded-[18px] border-o2o-gris-arena">
                    <p className='text-base font-bold w-full md:w-1/5 mb-4 md:mb-0'>Servicio {f.servicio.folio}</p>
                    <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-4">
                        <div>
                            <p><strong>Cliente:</strong> {f.servicio.administrador.nombre} {f.servicio.administrador.apellidos}</p>
                            <p><strong>Paciente:</strong> {f.servicio.paciente.nombre} {f.servicio.paciente.apellidos}</p>
                        </div>
                        <div>
                            <p><strong>Servicio:</strong> {f.servicio.folio}</p>
                            <p><strong>Estatus:</strong> {f.estatus_nombre}</p>
                        </div>
                        <div>
                            <p>
                                <strong>Conceptos de facturación:<br /></strong>
                                {f.citas.map((c, i) => (
                                    <span key={i}>Cita #{c.cita.cita_no} Monto ${c.cantidad}<br /></span>
                                ))}
                            </p>
                        </div>
                        <div>
                            <p>
                                <strong>Pago por servicio:</strong> ${f.servicio.pagos.sin_comision}
                            </p>
                            <p>
                                <strong>Monto sin facturar:</strong> ${f.servicio.pagos.sin_comision - montoFacturado}
                            </p>
                            <CustomButtonOval
                                className={'w-full text-center bg-o2o-aqua hover:bg-o2o-aqua-hover mt-2 md:mt-0'}
                                onClick={() => setModalFactura({ show: true, url: f.factura_pdf, firma: true })}
                            >
                                Visualizar factura
                            </CustomButtonOval>
                        </div>
                    </div>
                </div>
            ))}
            {modalFactura.show &&
                <PreviewPDF modal={modalFactura} setModal={setModalFactura} />
            }
        </div>
    )
}

export default ServiciosFacturar

"use client"
import { useLocation } from 'react-router-dom'
import SearchForm from '../../../components/SearchForm'
import { imgUrl } from '../../../helpers/recursosurl'
import React, { useEffect, useState } from 'react'
import Card from '../../../components/Card'
import { getEspecialidades } from '../../../helpers/configRoutes/backend_helper'

const Buscador = () => {
    const [img, setImg] = useState('');
    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setImg('home-search.png');
                break;
            case '/quienes-somos':
                setImg('quienes-search.png');
                break;
            case '/como-funciona':
                setImg('comofunciona-search.png');
                break;
            case '/central-atencion':
                setImg('centro-atencion.svg');
                break;
            case '/documentacion-informacion':
                setImg('centro-atencion.svg');
                break;
            default:
                setImg('quienes-search.png');
                break;
        }
    }, [])

    return (
        <div className="flex flex-col mt-[68px] justify-center max-w-o2o mx-auto" id="buscar-enfermero">
            <div className="w-full">
                <div className="relative items-center">
                    {location.pathname === '/' &&
                        <div className='absolute flex flex-col max-w-[293px] top-1/2 left-28 transform  -translate-y-1/2'>
                            <h1 className='mb-[18px]'>Cuidados de enfermería a domicilio</h1>
                            <p>Conecta con el especialista ideal para ti, tus seres queridos o tu negocio</p>
                        </div>
                    }
                    <img src={`${imgUrl}/${img}`} alt="Imagen" className="w-full h-auto hidden sm:block rounded-b-[18px]" />
                    <img src={`${imgUrl}/${img}`} alt="Imagen" className="w-full h-auto sm:hidden rounded-b-[18px]" />
                    <SearchForm />
                </div>
            </div>
        </div>
    )
}

export default Buscador
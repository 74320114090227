import React, { useEffect, useState } from "react";
import CustomButtonOval from "../../../components/buttons/CustomButtonOval";
import { imgUrl } from "../../../helpers/recursosurl";
import { pacientesResource, serviciosResource } from "../../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FormatCurrency } from "../../../helpers/CurencyFormat";



// const buscarUbicacionEnGoogleMaps = async () => {
//     if (sucursal.estado && sucursal.municipio) {
//         try {
//             const direccion = `${sucursal.colonia}, ${sucursal.colonia}, ${sucursal.municipio}, ${sucursal.estado}`;
//             const response = await fetch(
//                 `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
//                     direccion
//                 )}&key=AIzaSyBd2Yji4ITZox7e1giZIWQ5pIyJvfJ2aTQ`
//             );
//             const data = await response.json();

//             if (data.results.length > 0) {
//                 const ub = data.results[0].geometry.location;
//                 //ubicacion.lat = ub.lat
//                 //ubicacion.lng = ub.lng
//                 setUbicacion({ lat: ub.lat, lng: ub.lng });
//                 //return { lat: ubicacion.lat, lng: ubicacion.lng };
//             } else {
//                 console.error('No se pudo encontrar la ubicación para la dirección:', direccion);
//                 //return { lat: 0, lng: 0 };
//             }
//         } catch (error) {
//             console.error('Error al buscar la ubicación:', error);
//             //return { lat: 0, lng: 0 };
//         }
//     }
// };
//navigate('https://www.google.com/maps/place/Centro,+Sombrerete,+paciente.estado')
const PerfilDetallado = () => {

    const [paciente, setPacientes] = useState([]);
    const [solicitud, setSolicitud] = useState({});
    const navigate = useNavigate();
    const { id, id_servicio } = useParams()
    useEffect(() => {
        const getPacientes = async () => {
            if (id) {
                const response = await pacientesResource('show', {}, id)
                const responseServico = await serviciosResource('show', {}, id_servicio)
                if (response.status) {
                    setPacientes(response.paciente)
                }
                if (responseServico.status) {
                    setSolicitud(responseServico.servicio)
                }
            }
        }
        getPacientes()
    }, []);


    const openGoogleMaps = (address) => {
        const baseUrl = 'https://www.google.com/maps/search/?api=1&query=';
        const encodedAddress = encodeURIComponent(address);
        const fullUrl = `${baseUrl}${encodedAddress}`;
        window.open(fullUrl, '_blank');
    };

    return (
        <>
            <div className="flex flex-wrap max-w-[75%] ml-[19%] mr-full">

                <div className="p-4">
                    <b className="block mb-2 text-sm font-bold">Esta es tu central de información.</b>
                    <p className='text-sm'>Aquí puedes consultar y editar tus datos para conectar con clientes.</p>
                </div>

                <div className="flex flex-wrap w-full ml-[5%] mr-[5%]">
                    <div className="w-full flex flex-col items-start">
                        <div className="w-full md:w-4/5">
                            <div className="flex justify-between items-center mb-[18px]">
                                <CustomButtonOval className="flex items-center bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover" onClick={() => navigate('/servicios')}>
                                    <svg
                                        width="7"
                                        height="10"
                                        viewBox="0 0 7 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.80078 1L1.00078 5L5.80078 9"
                                            stroke="black"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    &nbsp;&nbsp;Regresar a solicitudes de servicio
                                </CustomButtonOval>
                            </div>
                        </div>
                        <hr className="border-t border-gray-300 w-full mb-2" />
                        <div className="bg-[#f1ece9] rounded-full w-[163px] h-[163px] flex items-center justify-center mt-[38px]">
                            <img
                                loading="lazy"
                                srcSet={paciente?.perfilImg || `${imgUrl}/user.png`}
                                className="rounded-full shrink-0 max-w-full aspect-square w-[164px]"
                            />
                        </div>
                        <div className="flex items-center">
                            <h3>{paciente?.nombre} {paciente?.apellidos}</h3>
                            
                        </div>

                        <div className="text-[24px] mb-[18px] font-semibold">
                            {paciente?.edad}
                        </div>
                        <div className="text-base mb-[18px] font">
                            {paciente?.diagnostico}
                        </div>
                        <div className="text-base font-semibold">
                            Domicilio de servicio
                        </div>
                        <div className="text-base font">
                            {paciente?.calle} {paciente?.numeroExterior} {paciente?.numeroInterior}, {paciente?.colonia}, C.P{paciente?.cp}

                        </div>
                        <div className="flex items-center text-base mb-[18px] font" onClick={() => openGoogleMaps(`${paciente?.calle} ${paciente?.numeroExterior} ${paciente?.numeroInterior} ${paciente?.colonia} ${paciente?.municipio_nombre} ${paciente?.estado_nombre}`)}>
                            <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.46841 0.995065C7.84937 0.798099 7.17406 0.685547 6.48468 0.685547C4.50094 0.685547 2.71418 1.58596 1.51831 3.00694L4.58536 5.58157L8.46841 0.995065Z" fill="#1A73E8" />
                                <path d="M1.51945 3.00781C0.57683 4.13333 0 5.59651 0 7.17224C0 8.39625 0.239173 9.38108 0.647175 10.2674L4.5865 5.58244L1.51945 3.00781Z" fill="#EA4335" />
                                <path d="M6.49884 4.69625C7.8776 4.69625 8.98906 5.8077 8.98906 7.18646C8.98906 7.79143 8.76395 8.35419 8.39816 8.79033C8.39816 8.79033 10.3537 6.45488 12.2671 4.18976C11.4793 2.67031 10.1146 1.51665 8.4685 0.996094L4.58545 5.5826C5.04973 5.04797 5.72504 4.69625 6.49884 4.69625Z" fill="#4285F4" />
                                <path d="M6.50262 9.66205C5.12385 9.66205 4.0124 8.5506 4.0124 7.17183C4.0124 6.56686 4.22343 6.0041 4.58923 5.58203L0.649902 10.267C1.32522 11.7583 2.45074 12.9683 3.6044 14.4737L8.40194 8.7757C7.93766 9.32439 7.26235 9.66205 6.50262 9.66205Z" fill="#FBBC04" />
                                <path d="M8.31371 16.0496C10.4803 12.659 12.9987 11.1255 12.9987 7.18616C12.9987 6.10284 12.7314 5.08987 12.2671 4.18945L3.60059 14.4739C3.96638 14.9523 4.34624 15.5009 4.71204 16.0637C6.03453 18.1037 5.66873 19.3137 6.51287 19.3137C7.35702 19.3137 6.99122 18.0896 8.31371 16.0496Z" fill="#34A853" />
                            </svg>
                            <span className="ml-2">Encuentra el domicilio en Google Maps</span>
                        </div>

                        <div className="text-base font-semibold">Intereses personales</div>
                        <div className="flex flex-wrap gap-2">
                            {paciente?.interesesPersonales?.map((interes, index) => (
                                <CustomButtonOval
                                    key={index}
                                    className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50"
                                >
                                    {interes.nombre}
                                </CustomButtonOval>
                            ))}
                        </div>
                        <div className="text-base mb-[18px] font-semibold">Descriptivo personal</div>
                        <div className="text-base mb-[18px] font">{paciente?.descripcionPersonal}</div>
                        <div className="text-base font-semibold">Me hace único</div>
                        <div className="text-base mb-[18px] font">{paciente?.informacionUnica}</div>
                        <div className="text-[24px]  font-semibold">
                            Cuidados requeridos
                        </div>
                        <div className="flex flex-wrap gap-2 mb-[18px]">
                            {paciente?.necesidad?.cuidados?.map((interes, index) => (
                                <CustomButtonOval
                                    key={index}
                                    className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50"
                                >
                                    {interes.nombre}
                                </CustomButtonOval>
                            ))}
                        </div>
                        {paciente.patologia &&
                            <>
                                <div className="text-[24px] mb-[18px] font-semibold w-full">
                                    <h3>Diagnóstico</h3>
                                </div>
                                <hr className="border-t border-gray-300 w-full mb-2" />
                                <div className="text-base font-semibold">Diagnístico médico</div>
                                <div className="text-base mb-[18px] font">{paciente?.diagnostico}</div>
                                <div className="text-base  font-semibold">Condiciones especiales</div>
                                <div className="text-base mb-[18px] font">{paciente?.condiciones_especiales}</div>
                                <div className="text-base  font-semibold">Médico responsable</div>
                                <div className="text-base  font">Dr. {paciente?.medico_nombre}</div>
                                <div className="text-base  font">Cédula profesional: {paciente?.medico_cedula}</div>
                                <div className="text-base  font">Tel: {paciente?.medico_telefono}</div>
                                <div className="text-base  font">Movil: {paciente?.medico_movil}</div>
                            </>
                        }
                        <div className="text-[24px] mb-[18px] font-semibold w-full mt-6">
                            <h3>Testimoniales</h3>
                        </div>
                        <hr className="border-t border-gray-300 w-full mb-2" />
                    </div>
                    <div className="" style={{ marginRight: '60px' }}>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <div className="text-base font-semibold text-left">Administrador / Representante del paciente</div>
                            <h2 className="text-[32px] font-bold mb-4 text-left">{paciente?.administrador?.nombre} {paciente?.administrador?.apellido}</h2>
                            <h3 className="text-[24px] font-bold mb-4 text-left">{paciente?.administrador?.edad}</h3>
                            <div className="text-base  font-semibold">Domicilio de servicio</div>
                            <div className="text-base  font">
                                {paciente?.administrador?.calle} {paciente?.administrador?.numeroExterior} {paciente?.administrador?.numeroInterior}, {paciente?.administrador?.colonia}, {paciente?.administrador?.municipio}, {paciente?.administrador?.estado}, C.P{paciente?.administrador?.cp}

                            </div>
                            <div className="flex items-center text-base mb-[18px] font" onClick={() => openGoogleMaps(`${paciente?.administrador?.calle} ${paciente?.administrador?.numeroExterior} ${paciente?.administrador?.numeroInterior} ${paciente?.administrador?.colonia} ${paciente?.administrador?.municipio} ${paciente?.administrador?.estado}`)}>
                                <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.46841 0.995065C7.84937 0.798099 7.17406 0.685547 6.48468 0.685547C4.50094 0.685547 2.71418 1.58596 1.51831 3.00694L4.58536 5.58157L8.46841 0.995065Z" fill="#1A73E8" />
                                    <path d="M1.51945 3.00781C0.57683 4.13333 0 5.59651 0 7.17224C0 8.39625 0.239173 9.38108 0.647175 10.2674L4.5865 5.58244L1.51945 3.00781Z" fill="#EA4335" />
                                    <path d="M6.49884 4.69625C7.8776 4.69625 8.98906 5.8077 8.98906 7.18646C8.98906 7.79143 8.76395 8.35419 8.39816 8.79033C8.39816 8.79033 10.3537 6.45488 12.2671 4.18976C11.4793 2.67031 10.1146 1.51665 8.4685 0.996094L4.58545 5.5826C5.04973 5.04797 5.72504 4.69625 6.49884 4.69625Z" fill="#4285F4" />
                                    <path d="M6.50262 9.66205C5.12385 9.66205 4.0124 8.5506 4.0124 7.17183C4.0124 6.56686 4.22343 6.0041 4.58923 5.58203L0.649902 10.267C1.32522 11.7583 2.45074 12.9683 3.6044 14.4737L8.40194 8.7757C7.93766 9.32439 7.26235 9.66205 6.50262 9.66205Z" fill="#FBBC04" />
                                    <path d="M8.31371 16.0496C10.4803 12.659 12.9987 11.1255 12.9987 7.18616C12.9987 6.10284 12.7314 5.08987 12.2671 4.18945L3.60059 14.4739C3.96638 14.9523 4.34624 15.5009 4.71204 16.0637C6.03453 18.1037 5.66873 19.3137 6.51287 19.3137C7.35702 19.3137 6.99122 18.0896 8.31371 16.0496Z" fill="#34A853" />
                                </svg>
                                <span className="ml-2">Encuentra el domicilio en Google Maps</span>
                            </div>
                            <div className="text-base  font-semibold">Datos de contacto</div>
                            <div className="text-base mb-[18px] font">
                                <p>Correo electronico: {paciente?.administrador?.email}</p>
                                <p>Telefono: {paciente?.administrador?.telefono}</p>
                                <p>movil: {paciente?.administrador?.movil}</p>
                            </div>
                            <div className="text-base mb-[18px] font-semibold">Intereses personales</div>
                            <div className="flex flex-wrap gap-2">
                                {paciente?.administrador?.interesesPersonales?.map((interes, index) => (
                                    <CustomButtonOval
                                        key={index}
                                        className="bg-o2o-gris-calido text-sm hover:bg-o2o-gris-calido-hover px-4 py-1 rounded-full border-b border-solid border-black border-opacity-50"
                                    >
                                        {interes.nombre}
                                    </CustomButtonOval>
                                ))}
                            </div>
                        </div>
                    </div>
                    {id_servicio &&
                        <>

                            <div className="text-[24px] mb-[18px] mt-[24px] font-semibold w-full">
                                <h3>Servicios solicitados</h3>
                            </div>
                            <hr className="border-t border-gray-300 w-full mb-2" />

                            <div className="flex flex-col items-center">
                                <div className="bg-o2o-gris-calido-hover shadow-md rounded-lg p-4 w-[339px] h-[390px]">
                                    <p className="text-base font-semibold">Solicitud de servicio</p>
                                    <h2 className="text-[32px] font-bold">{solicitud.folio}</h2>
                                    <hr className="border-t border-black w-full mb-2" />
                                    <div>
                                        <p className="text-sm">Periodo del Servicio</p>
                                        <p className="text-sm">{solicitud.fecha_inicial} al {solicitud.fecha_fin}</p>
                                        <p className="text-sm">Lunes a Viernes</p>
                                        <p className="text-sm">Turno {solicitud.tiempo?.nombre} {solicitud.turno?.nombre}</p>
                                    </div>
                                    <hr className="border-t border-black w-full mb-2" />
                                    <div>
                                        <p className="text-sm">Costo total del servicio</p>
                                        <p className="text-sm">{FormatCurrency(solicitud.pagos?.costo_total)}</p>
                                        <p className="text-sm">Pago servicios One 2 One</p>
                                        <p className="text-sm">{FormatCurrency(solicitud.pagos?.comision)}</p>
                                    </div>
                                    <hr className="border-t border-black w-full mb-2" />
                                    <div>
                                        <p className="text-base font-semibold">Honorarios</p>
                                        <p className="text-base font-bold">{FormatCurrency(solicitud.pagos?.sin_comision)}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

            </div>
        </>
    );

}

export default PerfilDetallado;
import {
    SET_CONFIG_PUBLIC,
} from "./actionTypes";

const initialState = {
    testimoniales_enfermeros:[],
    testimoniales_plataforma:[],
    calificables_plataforma:[],
    c_turnos:[],
    c_horarios:[],
    catEspecialidades:[],
    showBuscador:[],
};

const configPublic = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONFIG_PUBLIC:
            return {
                ...action.payload,
            };
        default:
            return state;
    }
};

export default configPublic;

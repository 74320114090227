import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useIsMobile } from '../../../store/hooks/useIsMobile';
import { imgUrl } from '../../../helpers/recursosurl';
import { getEstrellas } from '../../../helpers/recursos';

const CardsCalificacionCliente = ({ calificaciones }) => {
    const { isMobile } = useIsMobile();
    return (
        <>
            {/* CARRUSEL */}

            <div className='swiperContainer'>
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={20}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                >
                    {calificaciones.map((data, index) => (
                        <SwiperSlide key={index}>
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col justify-center px-5 py-1.5 max-w-full bg-orange-200 rounded-2xl shadow-sm ">
                                    <div className="flex gap-3">
                                        
                                        {new Array(parseInt(data?.calificacion) || 0).fill("").map((_, i) => (
                                            <img
                                                src="/icons/start-fill.svg"
                                                alt="star"
                                                key={i}
                                                className="w-4 h-4"
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="flex flex-col p-2 mt-5 w-full text-black rounded-xl shadow-sm bg-o2o-gris-arena">
                                    <div className="flex gap-5">
                                       
                                        <div className="flex flex-col shrink-0 my-auto w-fit">
                                            <div className="text-2xl w-full font-bold">{data.usuario?.informacion?.nombre}</div>
                                            <div className="text-sm w-full leading-4">{data.fecha}</div>
                                        </div>
                                    </div>
                                    <div className="mt-3 text-base leading-6">
                                        {data.testimonial}
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    )
}

export default CardsCalificacionCliente
import React from 'react'
import CardsCalificacionCliente from './CardsCalificacionCliente';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import CustomButtonOval from '../../../components/buttons/CustomButtonOval';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CardEnfermeroInfo = () => {
    const navigate = useNavigate()
    const testimoniales = useSelector((state) => state.configPublic.testimoniales_enfermeros || []);
    const cards = [
        {},
        {},
        {},
    ];

    return (
        <>
            <div className="relative flex flex-wrap justify-center items-center font mb-10">
                <div className="text-base text-black rounded-2xl">
                    <span className="text-2xl font-bold">
                        Reviews sobre nuestro equipo de enfermer@s
                    </span>
                </div>
            </div>
            <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
            >
                {testimoniales.map((data, index) => (
                    <SwiperSlide key={index}>
                        <div className="flex flex-wrap justify-center items-center font">
                            <div className="relative text-base text-black rounded-2xl shadow-xl bg-o2o-gris-calido   w-[398px] h-[636px]">
                                {/* Imagen */}
                                <img
                                    loading="lazy"
                                    srcSet={data.perfilImg}
                                    className="w-full aspect-[1.39] rounded-t-2xl"
                                />
                                {/* Contenido */}
                                <div className="flex flex-col px-5 pb-5 mt-5 w-full">
                                    <div className="flex flex-col pb-5 border-b border-solid border-stone-200">
                                        <div className="text-2xl font-bold">{data.nombre}</div>
                                        <div className="leading-[150%]">{data.edad}</div>
                                        <div className="mt-3 font-bold leading-6">
                                            {data.nivel_estudios} en enfermería
                                        </div>
                                        <div className="mt-3 leading-[150%]">Con especialidad en:</div>
                                        <div className="flex flex-wrap gap-1.5 content-start mt-2 text-xs leading-3 text-center whitespace-nowrap">
                                            {data.formacion_academica?.map((f, i) => (
                                                <div
                                                    key={i}
                                                    className="justify-center px-2 py-1.5 rounded-md border border-solid bg-stone-200 border-stone-200"
                                                >
                                                    {f.especialidad_nombre}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="mt-5 leading-6">{data.descripcion_profesional}</div>
                                    {/* CardsCalificacionCliente para móviles */}
                                    <div className="block lg:hidden mt-5">
                                        <CardsCalificacionCliente calificaciones={data.calificaciones} />
                                    </div>
                                </div>
                                {/* CardsCalificacionCliente para pantallas grandes */}
                                <div className="absolute lg:top-[140px] lg:ml-[330px] lg:w-[399px] hidden lg:block">
                                    <CardsCalificacionCliente calificaciones={data.calificaciones} />
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Sección final */}
            <div className="flex flex-wrap justify-center items-center font mt-10 ">
                <div className="text-base text-black rounded-2xl w-full lg:w-2/3 text-center mb-5">
                    <div className='w-[692px] justify-center text-center ml-[152px]'>
                        <span className="text-3xl lg:text-[48px] font-bold ml-[50px]">
                            La voz de nuestros enfermeros es igual de importante
                        </span>
                    </div>
                </div>
                <div className="text-base text-black rounded-2xl w-full lg:w-2/3 text-center mb-5">
                    <div className='w-[692px] justify-center text-center ml-[152px]'>
                        <span className="text-base font-normal">
                            Los profesionistas de One to One también califican el desempeño de los clientes que los
                            contratan, creando un antecedente que permite a colegas tomar las mejores decisiones respecto
                            a su trabajo.
                        </span>
                    </div>
                </div>
                <div className="text-base text-black rounded-2xl w-full lg:w-2/3 text-center flex justify-center space-x-4 font-semibold">
                    <CustomButtonOval
                        className="bg-o2o-gris hover:bg-o2o-gris-hover"
                        onClick={() => navigate('/quienes-somos')}
                    >
                        ¿Quiénes somos?
                    </CustomButtonOval>
                    <CustomButtonOval
                        className="bg-o2o-gris hover:bg-o2o-gris-hover"
                        onClick={() => navigate('/como-funciona')}
                    >
                        ¿Cómo funciona?
                    </CustomButtonOval>
                </div>
            </div>




        </>
    )
}

export default CardEnfermeroInfo
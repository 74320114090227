import React, { useState, useEffect } from "react";
import CustomButtonSquare from "../../buttons/CustomButtonSquare";
import CustomTextArea from "../../inputs/CustomTextArea";
import CustomInput from "../../inputs/CustomInput";
import CustomSelect from "../../inputs/CustomSelect";
import Divider from "../../Divider";
import CustomInputMask from "../../inputs/CustomInputMask";
import { getEspecialidades, getCuidados } from "../../../helpers/configRoutes/backend_helper";
import CustomInputMaskPerson from "../../inputs/CustomInputMaskPerson";

const Patologia = ({ validation }) => {
    const [c_especialidades, setCEspecialidades] = useState([])
    const [cuidados, setCuidados] = useState([])
    useEffect(() => {
        const getCatalogos = async () => {
            const response = await getEspecialidades()
            const responseCuid = await getCuidados()
            setCEspecialidades(response.especialidades)
            setCuidados(responseCuid.cuidados)
        }
        getCatalogos()
    }, []);

    /*const cuidados = [
        { id: 1, nombre: 'Acompañamiento' },
        { id: 2, nombre: 'Terapia física' },
        { id: 3, nombre: 'Aseo del paciente' },
        { id: 4, nombre: 'Alimentación' },
        { id: 5, nombre: 'Monitoreo de signos' },
        { id: 6, nombre: 'Asistencia médica' }
    ];*/

    const [descripcionEspecialidad, setDescripcionEspecialidad] = useState('')

    const agregarCuidados = (id) => {
        const cuidadosActuales = validation.values.cuidados || [];
        let nuevosCuidados = [];
        if (cuidadosActuales.filter(cuidado => cuidado.id == id).length > 0) {
            nuevosCuidados = cuidadosActuales.filter(cuidado => cuidado.id !== id)
            validation.setFieldValue('cuidados', nuevosCuidados);
        } else {
            nuevosCuidados = [...cuidadosActuales, { id: id }];
            validation.setFieldValue('cuidados', nuevosCuidados);
        }
    }

    return (
        <>
            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[60px] max-md:ml-0">
                <div className="flex flex-col px-5 pt-3 mt-3 w-full border-t border-black border-solid max-md:max-w-full">
                    <div className="w-full font-bold max-md:max-w-full">
                        ¿Padece una patología?
                    </div>
                    <div className="w-full flex flex-wrap gap-2 mt-2 max-md:flex-col max-md:gap-2">
                        <CustomButtonSquare
                            type='button'
                            className={`hover:bg-o2o-gris-hover ${validation.values.patologia ? 'bg-o2o-gris' : ''}`}
                            onClick={() => validation.setFieldValue('patologia', true)}
                        >
                            Si
                        </CustomButtonSquare>
                        <CustomButtonSquare
                            type='button'
                            className={`hover:bg-o2o-gris-hover ${!validation.values.patologia ? 'bg-o2o-gris' : ''}`}
                            onClick={() => validation.setFieldValue('patologia', false)}
                        >
                            No
                        </CustomButtonSquare>
                    </div>
                </div>
            </div>

            {validation.values.patologia && (
                <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[60px] max-md:ml-0">
                    <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            Diagnóstico médico
                        </div>
                        <CustomTextArea
                            rows={6}
                            placeholder='Por ejemplo: Soy una persona muy ordenada y estudiosa; también disfruto del deporte y del buen cine.'
                            name="diagnostico"
                            error={validation.errors?.diagnostico}
                            value={validation.values.diagnostico || ''}
                            onChange={validation.handleChange}
                        />
                    </div>

                    <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            Condiciones especiales
                        </div>
                        <CustomTextArea
                            rows={6}
                            placeholder='Por ejemplo: Me gusta la naturaleza y me encanta ver los amaneceres.'
                            name="condiciones_especiales"
                            error={validation.errors?.condiciones_especiales}
                            value={validation.values.condiciones_especiales || ''}
                            onChange={validation.handleChange}
                        />
                    </div>

                    <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            Médico responsable
                        </div>
                        <CustomInput
                            name="medico_nombre"
                            placeholder={'Médico responsable'}
                            type={'text'}
                            error={validation.errors?.medico_nombre}
                            value={validation.values.medico_nombre || ''}
                            onChange={validation.handleChange}
                        />
                    </div>

                    <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            Cédula profesional
                        </div>
                        <CustomInput
                            name="medico_cedula"
                            placeholder={'Cédula profesional'}
                            type={'text'}
                            error={validation.errors?.medico_cedula}
                            value={validation.values.medico_cedula || ''}
                            onChange={validation.handleChange}
                        />
                    </div>

                    <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            Teléfono
                        </div>
                        <CustomInputMaskPerson
                            name="medico_telefono"
                            mask={"9999999999"}
                            value={validation.values.medico_telefono || ''}
                            error={validation.errors?.medico_telefono}
                            type={'number'}
                            placeholder={'1234567890'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                    </div>

                    <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            Móvil
                        </div>
                        <CustomInputMaskPerson
                            name="medico_movil"
                            mask={"9999999999"}
                            value={validation.values.medico_movil || ''}
                            error={validation.errors?.medico_movil}
                            type={'number'}
                            placeholder={'1234567890'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                    </div>
                </div>
            )}

            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[60px] max-md:ml-0">
                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="self-start text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Requiere un enfermero especialista en:
                    </div>
                    <CustomSelect
                        name='especialidad_id'
                        options={[
                            { label: 'Seleccione una opción', value: 0 },
                            ...c_especialidades?.map(data => ({ value: data?.id, label: data?.nombre }))
                        ]}
                        value={validation.values.especialidad_id || ''}
                        onChange={validation.handleChange}
                        error={validation.errors?.especialidad_id}
                    />
                    {c_especialidades.map((esp, index) => {
                        if (esp.id == validation.values.especialidad_id) {
                            return <span key={index}>{esp.descripcion}</span>;
                        }
                    })}
                </div>
            </div>

            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[60px] max-md:ml-0">
                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="self-start text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Cuidados que ofrece
                    </div>
                    <div className="flex flex-wrap justify-start items-center mt-6 gap-3">
                        {cuidados?.map((data, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`justify-center px-2.5 py-1 whitespace-nowrap rounded-md border border-solid border-stone-100 hover:bg-o2o-gris-hover ${validation.values.cuidados?.filter(res => res?.id === data?.id).length > 0 ? 'bg-o2o-gris-hover' : ''}`}
                                onClick={() => agregarCuidados(data?.id)}
                            >
                                {data.nombre}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[60px] max-md:ml-0">
                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="self-start text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Alergias
                    </div>
                    <CustomInput
                        name="alergias"
                        value={validation.values?.alergias || ''}
                        placeholder={'Alergias'}
                        type={'text'}
                        onChange={validation.handleChange}
                        error={validation.errors?.alergias}
                    />
                </div>
            </div>

            <div className="flex flex-wrap max-w-[90%] ml-[19%] mt-[60px] max-md:ml-0">
                <div className="w-full md:w-1/2 px-2 mb-3 max-md:w-full">
                    <div className="self-start text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                        Información adicional relevante que deba saber el profesional de salud
                    </div>
                    <CustomTextArea
                        rows={2}
                        placeholder=''
                        name="informacion_adicional"
                        value={validation.values?.informacion_adicional || ''}
                        onChange={validation.handleChange}
                        error={validation.errors?.informacion_adicional}
                    />
                </div>
                <div className='w-full'>
                    <Divider />
                </div>
            </div>
        </>

    )
}

export default Patologia
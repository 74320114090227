import React, { useEffect, useState } from "react";
import { imgUrl } from '../../../helpers/recursosurl'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik';
import * as Yup from "yup";
import Card from "../../../components/Card";
import CalendarizadoBusquedas from "../../../components/registro/administrador/CalendarizadoBusquedas";
import { estados, getCps, getSexosGeneros, municipios, paises, getEnfermerosPublico } from "../../../helpers/configRoutes/backend_helper";
import CustomSelect from "../../../components/inputs/CustomSelect";
import CustomInput from "../../../components/inputs/CustomInput";
import CustomTextArea from "../../../components/inputs/CustomTextArea";
import ResultadosPublico from "./ResultadosPublico";
import BarraBusquedaPublico from "./BarraBusquedaPublico";
import { set_resultados } from "../../../store/busquedasPublicas/action";
import { Navigate, useNavigate } from "react-router-dom";

const Busqueda = () => {
    const navigate = useNavigate()
    const [img, setImg] = useState('');
    const { c_turnos, c_horarios, catEspecialidades } = useSelector((state) => state.configPublic);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showTurno, setShowTurno] = useState(false);
    const [enfermeros, setEnfermeros] = useState([])
    const [meta, setMeta] = useState({})

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
        if (showBuscador) {
            setShowBuscador(!showBuscador);
        }
        if (showTurno) {
            setShowTurno(!showTurno);
        }
    };

    const toggleBuscador = () => {
        setShowBuscador(!showBuscador);
        if (showCalendar) {
            setShowCalendar(!showCalendar);
        }
        if (showTurno) {
            setShowTurno(!showTurno);
        }
    };
    const toggleTurno = () => {
        setShowTurno(!showTurno);
        if (showCalendar) {
            setShowCalendar(!showCalendar);
        }
        if (showBuscador) {
            setShowBuscador(!showBuscador);
        }
    };
    const oneDayBefore = new Date();
    oneDayBefore.setDate(oneDayBefore.getDate() - 1);
    const dispatch = useDispatch()
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            especialidad_id: '',
            turno_id: '',
            tiempo_id: '',
            fecha_inicio: '',
            fecha_fin: '',
            dias: [],
            fechaNacimiento: '',
            sexo_id: '',
            diagnostico: '',
            condiciones_especiales: '',
        },
        validationSchema: Yup.object({
            especialidad_id: Yup.number().required('Campo requerido'),
            turno_id: Yup.number().required('Campo requerido'),
            tiempo_id: Yup.number().required('Campo requerido'),
            fecha_inicio: Yup.date().required('Campo requerido').min(oneDayBefore, 'La fecha debe ser actual o futura'),
            fecha_fin: Yup.date()
                .required('Campo requerido')
                .test('fecha-mayor', 'La fecha de término debe ser posterior a la fecha de inicio', function (value) {
                    const fechaInicio = this.parent.fecha_inicio;
                    return fechaInicio < value;
                }),
            dias: Yup.array().of(Yup.number()).required('Campo requerido'),
            cp: Yup.string().required('Campo requerido'),
            cp_id: Yup.string().required('Campo requerido'),
            pais: Yup.string().required('Campo requerido'),
            estado: Yup.string().required('Campo requerido'),
            municipio: Yup.string().required('Campo requerido'),
            colonia: Yup.string().required('Campo requerido'),
            sexo_id: Yup.string().required('Campo requerido'),
            diagnostico: Yup.string().required('Campo requerido'),
            condiciones_especiales: Yup.string().required('Campo requerido'),
        }),
        onSubmit: async (values, { setErrors }) => {
            const response = await getEnfermerosPublico(values)
            setEnfermeros(response.enfermeros);
            setMeta(response.meta)
            if(response.status){
                dispatch(set_resultados(response.enfermeros))
                navigate('/busqueda-resultados')
            }
        },
    });
    const [showBuscador, setShowBuscador] = useState(false)

    const [sexos, setSexos] = useState([])
    useEffect(() => {
        const getSexos = async () => {
            const response = await getSexosGeneros();
            setSexos(response.sexos)
        }
        getSexos()
    }, []);


    const [c_municipios, setCMunicipios] = useState([]);
    const [c_paises, setCpaises] = useState([])
    const [c_estados, setCEstados] = useState([])
    const [cps, setCps] = useState([])
    const [showOptions, setShowOptions] = useState(false)

    useEffect(() => {
        const getPaises = async () => {
            const response = await paises();
            const responseEstados = await estados();
            setCpaises(response.paises)
            setCEstados(responseEstados.estados)
        }
        getPaises()
    }, []);

    useEffect(() => {
        const getMunicipios = async () => {
            const response = await municipios({ params: { estado_id: validation.values.estado } });
            setCMunicipios(response.municipios)
        }
        getMunicipios()
    }, [validation.values.estado]);

    const loadOptions = async (input) => {
        validation.setFieldValue('cp_id', null)
        if (input.length > 3) {
            setShowOptions(true)
            const options = await getCps({ params: { search: input } });
            setCps(options.cps);
        } else {
            setShowOptions(false)
        }
    };

    const setOptionCp = (option) => {
        validation.setFieldValue('cp', `${option.cp} - ${option.asentamiento}`)
        validation.setFieldValue('cp_id', option.id)
        setShowOptions(false)
        validation.setFieldValue('estado', option.estado_id)
        validation.setFieldValue('pais', 1)
        validation.setFieldValue('municipio', option.municipio_id)
        validation.setFieldValue('colonia', option.asentamiento)
    }

    return (
        <>
            <div className="flex flex-col mt-[68px] justify-center max-w-o2o mx-auto" id="buscar-enfermero">
                <div className="w-full">
                    <form onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        console.log(validation.values);
                        return false;
                    }}
                    >
                        <div className="relative items-center">
                            <div className='absolute flex flex-col w-1/3 top-1/2 left-18 transform-translate-y-1/2 mr-5'>
                                <h1 className='mb-[18px]'>El enfermero ideal está a unos clicks de distancia</h1>
                                <p>Usa el buscador y nuestra sofisticada plataforma te presentará al enfermero que tú o tu paciente necesitan.</p>
                            </div>
                            <img src={`${imgUrl}/buscar1.png`} alt="Imagen" className="w-full ml-10 h-auto hidden sm:block rounded-b-[18px]" />
                        </div>
                        <div className="p-1 flex-wrap max-md:max-w-full max-md:ml-0">
                            <div className="flex items-center bg-o2o-gris-calido p-0 mt-4 rounded text-xl">
                                <span className="bg-o2o-morado text-white p-3 rounded text-xl">1</span>
                                <span className="ml-2 text-sm">Selecciona un enfermero con especialidad en</span>
                            </div>
                            <Card
                                title={<>
                                    <span className='text-gray-400'>Con especialidad en</span>
                                    <div className="border-t border-gray-300 mt-2"></div>
                                </>}
                                content={
                                    <>
                                        <div className=' mx-auto'>
                                            <div className='flex'>
                                                <div className='lg:w-[60%] w-full'>
                                                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 overflow-x-auto">
                                                        {catEspecialidades.map((item, index) => (
                                                            <button
                                                                key={index}
                                                                className={`hover:text-o2o-morado hover:font-bold text-start text-menuBusqueda ${validation.values?.especialidad_id === item.id ? 'text-o2o-morado font-bold' : ''}`}
                                                                index={index}
                                                                onClick={() => [validation.setFieldValue('especialidad_id', item.id), validation.setFieldValue('especialidad', item)]}
                                                            >
                                                                {item.nombre}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='lg:w-[30%] w-full ml-[18px]'>
                                                    <h3 className='text-black'>{validation.values?.especialidad?.nombre}</h3>
                                                    <p className='text-sm'>{validation.values?.especialidad?.descripcion}</p>
                                                </div>
                                                <div className='lg:w-[10%] w-full ml-[18px]'>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                tags={[]}
                            />
                        </div>
                        <div className="p-1 flex-wrap max-md:max-w-full max-md:ml-0">
                            <div className="flex items-center bg-o2o-gris-calido p-0 mt-4 rounded text-xl">
                                <span className="bg-o2o-morado text-white p-3 rounded text-xl">2</span>
                                <span className="ml-2 text-sm">Define las características del paciente</span>
                            </div>
                            <div className="grid border grid-cols-1 lg:grid-cols-3 gap-4 overflow-x-auto mt-3">
                                <div className="w-full px-2 mb-3 max-md:w-full">
                                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                        Fecha de nacimiento
                                    </div>
                                    <CustomInput
                                        name="fechaNacimiento"
                                        value={validation.values.fechaNacimiento || ''}
                                        error={validation.errors?.fechaNacimiento}
                                        type={'date'}
                                        onChange={validation.handleChange}
                                    />
                                    <div className="text-sm font-bold leading-4 text-black mb-2">
                                        Sexo
                                    </div>
                                    <div className="justify-center">
                                        <CustomSelect
                                            name="sexo_id"
                                            options={[
                                                { label: 'Seleccione una opción', value: 0 },
                                                ...sexos?.map(tipo => ({ value: tipo?.id, label: tipo?.nombre }))
                                            ]}
                                            value={validation.values.sexo_id || ''}
                                            error={validation.errors?.sexo_id}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="w-full  px-2 mb-3 max-md:w-full">
                                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                        Diagnóstico médico
                                    </div>
                                    <CustomTextArea
                                        rows={6}
                                        placeholder='Por ejemplo: Soy una persona muy ordenada y estudiosa; también disfruto del deporte y del buen cine.'
                                        name="diagnostico"
                                        error={validation.errors?.diagnostico}
                                        value={validation.values.diagnostico || ''}
                                        onChange={validation.handleChange}
                                    />
                                </div>

                                <div className="w-full  px-2 mb-3 max-md:w-full">
                                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                        Condiciones especiales
                                    </div>
                                    <CustomTextArea
                                        rows={6}
                                        placeholder='Por ejemplo: Me gusta la naturaleza y me encanta ver los amaneceres.'
                                        name="condiciones_especiales"
                                        error={validation.errors?.condiciones_especiales}
                                        value={validation.values.condiciones_especiales || ''}
                                        onChange={validation.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-1 flex-wrap max-md:max-w-full max-md:ml-0">
                            <div className="flex items-center bg-o2o-gris-calido p-0 mt-4 rounded text-xl">
                                <span className="bg-o2o-morado text-white p-3 rounded text-xl">3</span>
                                <span className="ml-2 text-sm">Elige la(s) fecha(s), turno y horario de servicio</span>
                            </div>
                            <CalendarizadoBusquedas validation={validation} />
                        </div>
                        <div className="p-1 flex-wrap max-md:max-w-full max-md:ml-0">
                            <div className="flex items-center bg-o2o-gris-calido p-0 mt-4 rounded text-xl">
                                <span className="bg-o2o-morado text-white p-3 rounded text-xl">4</span>
                                <span className="ml-2 text-sm">Define la ubicación del servicio</span>
                            </div>
                            <div className="grid border grid-cols-1 lg:grid-cols-4 gap-4 overflow-x-auto mt-3">
                                <div className="w-full px-2 mb-3">
                                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                        Código postal
                                    </div>
                                    <div className="justify-center ">
                                        <CustomInput
                                            name="cp"
                                            value={validation.values.cp || ''}
                                            error={validation.errors?.cp}
                                            placeholder={'Código postal'}
                                            type={'text'}
                                            onChange={(e) => [validation.setFieldValue('cp', e.target.value), loadOptions(e.target.value)]}
                                            maxLength="5"
                                        />
                                        {showOptions && (
                                            <div className="absolute mt-1 w-[560px] border border-gray-300 rounded-lg shadow-lg bg-white z-10 max-h-40 overflow-y-auto">
                                                {cps.length > 0 ? (
                                                    cps.map(option => (
                                                        <div
                                                            key={option.id}
                                                            onClick={() => setOptionCp(option)}
                                                            className="p-2 cursor-pointer hover:bg-gray-100"
                                                        >
                                                            {option.cp} - {option.asentamiento}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="p-2 text-gray-500">No options found</div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full  px-2 mb-3">
                                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                        Municipio
                                    </div>
                                    <div className="justify-center ">
                                        <CustomSelect
                                            name="municipio"
                                            options={[
                                                { label: 'Seleccione una opción.', value: 0 },
                                                ...c_municipios.map(tipo => {
                                                    return { value: tipo?.id, label: tipo?.nombre }
                                                })
                                            ]}
                                            disabled={true}
                                            value={validation.values.municipio || ''}
                                            error={validation.errors?.municipio}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full  px-2 mb-3">
                                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                        Colonia
                                    </div>
                                    <div className="justify-center ">
                                        <CustomInput
                                            name="colonia"
                                            value={validation.values.colonia || ''}
                                            error={validation.errors?.colonia}
                                            placeholder={'Colonia'}
                                            type={'text'}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full  px-2 mb-3">
                                    <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                                        Estado
                                    </div>
                                    <div className="justify-center ">
                                        <CustomSelect
                                            name="estado"
                                            options={[
                                                { label: 'Seleccione una opción.', value: 0 },
                                                ...c_estados.map(tipo => {
                                                    return { value: tipo?.id, label: tipo?.nombre }
                                                })
                                            ]}
                                            disabled={true}
                                            value={validation.values.estado || ''}
                                            error={validation.errors?.estado}
                                            onChange={validation.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-3 text-2xl font-bold text-black max-md:flex-wrap mt-10 ">
                            <button className="grow justify-center px-24 py-5 bg-o2o-aqua shadow-sm rounded-[36px] w-fit max-md:px-8 max-md:max-w-full hover:bg-o2o-aqua-hover" type="submit">
                                Guardar información y continuar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Busqueda
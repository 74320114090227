import { warningToast } from "./Toast";

export const showFormErrors = (validation) => {
    let errors = validation.errors;
    let firstErrorField = null;
    for (const key in errors) {
        if (errors[key]) {
            warningToast(`Error en ${key}: ${errors[key]}`);
            if (!firstErrorField) {
                firstErrorField = key;
            }
        }
    }

    if (firstErrorField) {
        const errorElement = document.getElementsByName(firstErrorField)[0];
        if (errorElement) {
            errorElement.focus();
        }
    }
};
import {
    SET_CONFIG,
  } from "./actionTypes"
  
  
  export const setConfig = config => {
    return {
      type: SET_CONFIG,
      payload: config
    }
  }
import React, { useState, useEffect } from "react";
import FileInput from '../inputs/FileInput'
import CustomInput from '../inputs/CustomInput'
import CustomSelect from '../inputs/CustomSelect'
import { paises, municipios, estados, getCps } from "../../helpers/configRoutes/backend_helper";

const DireccionDatos = ({ handleFileChange, validation, titulos = true }) => {
    const [c_municipios, setCMunicipios] = useState([]);
    const [c_paises, setCpaises] = useState([])
    const [c_estados, setCEstados] = useState([])
    const [cps, setCps] = useState([])
    const [showOptions, setShowOptions] = useState(false)

    useEffect(() => {
        const getPaises = async () => {
            const response = await paises();
            const responseEstados = await estados();
            setCpaises(response.paises)
            setCEstados(responseEstados.estados)
        }
        getPaises()
    }, []);

    useEffect(() => {
        const getMunicipios = async () => {
            const response = await municipios({ params: { estado_id: validation.values.estado } });
            setCMunicipios(response.municipios)
        }
        getMunicipios()
    }, [validation.values.estado]);

    const loadOptions = async (input) => {
        validation.setFieldValue('cp_id', null)
        if (input.length > 3) {
            setShowOptions(true)
            const options = await getCps({ params: { search: input } });
            setCps(options.cps);
        } else {
            setShowOptions(false)
        }
    };

    const setOptionCp = (option) => {
        validation.setFieldValue('cp', `${option.cp} - ${option.asentamiento}`)
        validation.setFieldValue('cp_id', option.id)
        setShowOptions(false)
        validation.setFieldValue('estado', option.estado_id)
        validation.setFieldValue('pais', 1)
        validation.setFieldValue('municipio', option.municipio_id)
        validation.setFieldValue('colonia', option.asentamiento)
    }

    return (
        <>
            {titulos &&
                <>
                    <div className="mt-5 w-full text-base font-bold leading-6 text-black max-md:max-w-full">
                        Dirección
                    </div>
                    <div className='w-full border-t border-black border-solid mb-3'></div>
                </>
            }
            <div className="w-full md:w-1/2 px-2 mb-3 ">
                <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                    Calle
                </div>
                <div className="justify-center ">
                    <CustomInput
                        name="calle"
                        value={validation.values.calle || ''}
                        error={validation.errors?.calle}
                        placeholder={'Calle'}
                        type={'text'}
                        onChange={validation.handleChange}
                    />

                </div>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-3">
                <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                    Código postal
                </div>
                <div className="justify-center ">
                    <CustomInput
                        name="cp"
                        value={validation.values.cp || ''}
                        error={validation.errors?.cp}
                        placeholder={'Código postal'}
                        type={'text'}
                        onChange={(e) => [validation.setFieldValue('cp', e.target.value), loadOptions(e.target.value)]}
                        maxLength="5"
                    />
                    {showOptions && (
                        <div className="absolute mt-1 w-[560px] border border-gray-300 rounded-lg shadow-lg bg-white z-10 max-h-40 overflow-y-auto">
                            {cps.length > 0 ? (
                                cps.map(option => (
                                    <div
                                        key={option.id}
                                        onClick={() => setOptionCp(option)}
                                        className="p-2 cursor-pointer hover:bg-gray-100"
                                    >
                                        {option.cp} - {option.asentamiento}
                                    </div>
                                ))
                            ) : (
                                <div className="p-2 text-gray-500">No options found</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-3">
                <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                    Número exterior
                </div>
                <div className="justify-center ">
                    <CustomInput
                        name="numeroExterior"
                        value={validation.values.numeroExterior || ''}
                        error={validation.errors?.numeroExterior}
                        placeholder={'Número exterior'}
                        type={'text'}
                        onChange={validation.handleChange}
                    />
                </div>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-3">
                <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                    Número interior
                </div>
                <div className="justify-center ">
                    <CustomInput
                        name="numeroInterior"
                        value={validation.values.numeroInterior || ''}
                        error={validation.errors?.numeroInterior}
                        placeholder={'Número interior'}
                        type={'text'}
                        onChange={validation.handleChange}
                    />
                </div>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-3">
                <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                    Municipio
                </div>
                <div className="justify-center ">
                    <CustomSelect
                        name="municipio"
                        options={[
                            { label: 'Seleccione una opción.', value: 0 },
                            ...c_municipios.map(tipo => {
                                return { value: tipo?.id, label: tipo?.nombre }
                            })
                        ]}
                        disabled={true}
                        value={validation.values.municipio || ''}
                        error={validation.errors?.municipio}
                        onChange={validation.handleChange}
                    />
                </div>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-3">
                <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                    Colonia
                </div>
                <div className="justify-center ">
                    <CustomInput
                        name="colonia"
                        value={validation.values.colonia || ''}
                        error={validation.errors?.colonia}
                        placeholder={'Colonia'}
                        type={'text'}
                        onChange={validation.handleChange}
                    />
                </div>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-3">
                <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                    Estado
                </div>
                <div className="justify-center ">
                    <CustomSelect
                        name="estado"
                        options={[
                            { label: 'Seleccione una opción.', value: 0 },
                            ...c_estados.map(tipo => {
                                return { value: tipo?.id, label: tipo?.nombre }
                            })
                        ]}
                        disabled={true}
                        value={validation.values.estado || ''}
                        error={validation.errors?.estado}
                        onChange={validation.handleChange}
                    />
                </div>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-3">
                <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                    País
                </div>
                <div className="justify-center ">
                    <CustomSelect
                        name="pais"
                        options={[
                            { label: 'Seleccione una opción.', value: 0 },
                            ...c_paises?.map(tipo => {
                                return { value: tipo?.id, label: tipo?.nombre }
                            })
                        ]}
                        disabled={true}
                        value={validation.values.pais || ''}
                        error={validation.errors?.pais}
                        onChange={validation.handleChange}
                    />
                </div>
            </div>



            {
                /*
                    <div className="w-full md:w-1/2 px-2 mb-3 ">
                        <div className="text-sm font-bold leading-4 text-black max-md:max-w-full mb-2">
                            Comprobante de domicilio
                        </div>
                        <div className="justify-center ">
                            <FileInput
                                format={'pdf'}
                                onChange={(e) => handleFileChange(e, 'comprobante_domicilio', 'pdf')}
                            />
                            <span className="text-red-600">
                                {validation.errors?.comprobante_domicilio ? 'Error en el archivo' : ''}
                            </span>
                        </div>
                    </div>
                    */
            }
        </>
    );
}

export default DireccionDatos